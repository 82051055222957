import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid , Button , Link} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    margin: "1em 0",
  },
  containerTitle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#EE6B04",
    fontWeight: "bolder",
  },
  typography: {
    margin: "2em 0",
    // textAlign: "justify",
  },
  
  typographyTitle: {
    textAlign: "center",
    "&:hover":{
      textDecoration: "underline",
      },
  },
  link: {
    margin: theme.spacing(0),
    color: "#0060A9",
    textTransform: "none",
    // "&:hover": {
    //   textDecoration: "none",
    // },
  },
  linkParagraph: {
    color: "#A60303",
    fontWeight: "bolder",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkCountry: {
    color: "#A60303",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  nexuraContent: {
    // background: "#A60303bb",
  },
  gefeticContent: {
    // background: "#0B3DC2bb",
  },
  b: {
    color: "#777",
  },
  card: {
    // background: "#ff0",
    height: "100%",
    maxWidth: "85%",
    padding: "0 5%",
  },
  parent: {
    margin: "5em",
    position: "relative",
    textAlign: "justify",
    transform:'translateY(+60px)'
  },
  Iconstyle:{
    textAlign: "center",
    color: "#0060A9",
    fontSize: "15vh",
    "&:hover": {
      transform: "scale(0.9)"
    },
  },

  BtnLink:{
    display: "block",
    width: "75%",
    height: "90%",
    alignItems: "center",
    borderRadius: "10px",
    padding: "0  5px 30px 5px",
    textAlign: "center",
    color: "#0060A9",
    boxShadow: "2px 2px 2px 2px #F3F0F0",
    "&:hover": {
        border: "1px solid #EE6B04",
        color: "black",
        transform: "scale(1.1)"
    },
  },
  BtnLinkXS:{
    display: "block",
    width: "100%",
    height: "auto",
    margin: "10px 0 10px 0 ",
    alignItems: "center",
    borderRadius: "10px",
    padding: "15px 10px ",
    textAlign: "center",
    color: "#0060A9",
    boxShadow: "2px 2px 2px 2px #F3F0F0",
    "&:hover": {
        border: "1px solid #EE6B04",
        color: "black",
        transform: "scale(1.1)"
    },
  },
  media: {
    maxWidth: "50%",
    paddingLeft: "25%",
  },
}));

export default function InfoConvocatorias() {
  const classes = useStyles();
  const XS = useMediaQuery("(max-width:600px)");
  const SM = useMediaQuery("(max-width:960px)");
  return (
    <div className={classes.parent}>
      <Typography variant='h4' className={classes.containerTitle}>
        Invitación privada
      </Typography>
      <Typography
        variant={XS ? "subtitle2" : "h6"}
        className={classes.container}
      >
        Aquí se encuentra publicada información referente a la invitación privada - divulgación de resultados en el marco del proyecto.
      </Typography>
      <Typography
          variant={XS ? "subtitle2" : "h6"}
          className={classes.container}
      >
          Consultar más Información de divulgación en   {" "}
          <Link
              className={classes.link}
              target="_blank"
              href="https://community.secop.gov.co/Public/Tendering/OpportunityDetail/Index?noticeUID=CO1.NTC.2301410&isFromPublicArea=True&isModal=False"
          >
              https://community.secop.gov.co
          </Link>

      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
          <Button variant="outlined" target="_blank" href= {"contratacion/1. INVITACIÓN PRIVADA - Divulgación de resultados.pdf"}  className={XS  || SM ?  classes.BtnLinkXS :classes.BtnLink}> 
              <PictureAsPdfIcon className={classes.Iconstyle}/> 
              <br/>1.  INVITACIÓN PRIVADA - Divulgación de resultados
          </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
          <Button variant="outlined" target="_blank" href= {"contratacion/2. Evaluación de proveedor_ Divulgación resultados.pdf"}  className={XS  || SM ?  classes.BtnLinkXS : classes.BtnLink}> 
                <PictureAsPdfIcon className={classes.Iconstyle}/> 
                    <br/>2. Evaluación de proveedor - Divulgación resultados
          </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
           <Button variant="outlined" target="_blank" href= {"contratacion/3. Acta comité de contratación.pdf"}  className={XS  || SM ?  classes.BtnLinkXS :classes.BtnLink}> 
                <PictureAsPdfIcon className={classes.Iconstyle}/> 
                    <br/>3.  Acta comité de contratación
          </Button>
        </Grid>
      </Grid>

      {/* MAQUILACION DE KIOSKOS */}
      <Typography
        variant={XS ? "subtitle2" : "h6"}
        className={classes.container}
      >
        Aquí se encuentra publicada información referente a la Maquilación de KIOSKOS - en el marco del proyecto.
      </Typography>
      <Typography
          variant={XS ? "subtitle2" : "h6"}
          className={classes.container}
      >
          Consultar más Información de Maquilación de KIOSKOS  {" "}
          <Link
              className={classes.link}
              target="_blank"
              href="https://community.secop.gov.co/Public/Tendering/OpportunityDetail/Index?noticeUID=CO1.NTC.2504502&isFromPublicArea=True&isModal=False"
          >
              https://community.secop.gov.co
          </Link>

      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
          <Button variant="outlined" target="_blank" href= {"contratacion/1. TÉRMINOS INVITACIÓN PRIVADA- Maquilación de kioscos.pdf"}  className={XS  || SM ?  classes.BtnLinkXS :classes.BtnLink}> 
              <PictureAsPdfIcon className={classes.Iconstyle}/> 
              <br/>1. TÉRMINOS INVITACIÓN PRIVADA- Maquilación de kioscos
          </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
          <Button variant="outlined" target="_blank" href= {"contratacion/ADENDA 1. TÉRMINOS INVITACIÓN PRIVADA.pdf"}  className={XS  || SM ?  classes.BtnLinkXS : classes.BtnLink}> 
                <PictureAsPdfIcon className={classes.Iconstyle}/> 
                    <br/>2. ADENDA 1. TÉRMINOS INVITACIÓN PRIVADA
          </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
           <Button variant="outlined" target="_blank" href= {"contratacion/3. Acta comité de contratación - Se declara desierta la invitación.pdf"}  className={XS  || SM ?  classes.BtnLinkXS :classes.BtnLink}> 
                <PictureAsPdfIcon className={classes.Iconstyle}/> 
                    <br/>3. Acta comité de contratación - Se declara desierta la invitación
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
