import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    margin: "1em 0",
  },
  containerTitle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#EE6B04",
    fontWeight: "bolder",
  },
  typography: {
    margin: "2em 0",
    // textAlign: "justify",
  },
  typographyTitle: {
    textAlign: "center",
    "&:hover":{
      textDecoration: "underline",
      },
  },

  link: {
    margin: theme.spacing(0),
    color: "#0060A9",
    textTransform: "none",
    // "&:hover": {
    //   textDecoration: "none",
    // },
  },
  parent: {
    margin: "5em",
    position: "relative",
    textAlign: "justify",
    transform: "translateY(+60px)",
  },
}));

export default function InfoContratacion() {
  const classes = useStyles();
  const XS = useMediaQuery("(max-width:600px)");
  // const SM = useMediaQuery("(max-width:960px)");
  return (
    <div className={classes.parent}>
        <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        >
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                <Typography variant='h4' className={classes.containerTitle}>
                    Información contractual
                </Typography>
                <Typography
                    variant={XS ? "subtitle2" : "h6"}
                    className={classes.container}
                >
                    Consultar más Información de contratación en   {" "}
                    <Link
                        className={classes.link}
                        target="_blank"
                        href="https://colombiacompra.gov.co/secop/consulte-en-el-secop-ii"
                    >
                        www.colombiacompra.gov.co 
                    </Link>

                </Typography>   
            </Grid>
            
        </Grid>
      

    </div>
  );
}
