export const states = [
  {
    name: "Fundación",
    location: {
      longitude: "-73.9072394",
      latitude: "10.4587146",
    },
    image:'fundacion.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/5",
    population: "56.107 habitantes",
    sites: [
      {
        name: [
          "Capilla del Colegio Sagrada Familia",
          "Iglesia María Auxiliadora",
          "Iglesia Señor de los Milagros",
          "Fuente del Barrio Vera Judith",
          "Parque de los Varados (Desempleados)",
          "Templo de San Rafael",
        ],
      },
    ],
    polygon: [
      [-74.21179962158203, 10.517299652099634],
      [-74.18009948730469, 10.504400253295923],
      [-74.17030334472656, 10.497400283813501],
      [-74.15760040283203, 10.48470020294192],
      [-74.14720153808594, 10.473099708557156],
      [-74.1397018432617, 10.46730041503909],
      [-74.12760162353516, 10.464300155639675],
      [-74.12190246582031, 10.456800460815456],
      [-74.10569763183594, 10.45149993896487],
      [-74.09239959716797, 10.432399749755886],
      [-74.0780029296875, 10.421400070190456],
      [-74.06819915771484, 10.415599822998075],
      [-74.06792449951172, 10.415534019470243],
      [-74.04340362548828, 10.409700393676786],
      [-74.02670288085938, 10.41189956665042],
      [-74.01519775390625, 10.420000076293976],
      [-73.99909973144531, 10.435500144958523],
      [-73.99160003662108, 10.445899963378935],
      [-73.98750305175781, 10.452199935913114],
      [-73.97260284423828, 10.454400062561062],
      [-73.95580291748047, 10.457300186157253],
      [-73.94550323486328, 10.470499992370632],
      [-73.93569946289061, 10.480299949646025],
      [-73.92130279541016, 10.486000061035181],
      [-73.8947982788086, 10.486499786376983],
      [-73.8572998046875, 10.49380016326907],
      [-73.82849884033203, 10.502900123596222],
      [-73.8095016479492, 10.510399818420437],
      [-73.79910278320312, 10.514399528503441],
      [-73.77950286865234, 10.521800041198754],
      [-73.76679992675781, 10.533900260925318],
      [-73.75299835205077, 10.542499542236353],
      [-73.74320220947266, 10.537799835205101],
      [-73.72820281982422, 10.545800209045431],
      [-73.7229995727539, 10.557399749755882],
      [-73.70690155029297, 10.560799598693873],
      [-73.70169830322266, 10.576899528503441],
      [-73.7040023803711, 10.600000381469751],
      [-73.7029037475586, 10.618499755859398],
      [-73.68669891357422, 10.629400253295922],
      [-73.67350006103516, 10.629400253295923],
      [-73.6428985595703, 10.637299537658713],
      [-73.6428985595703, 10.629799842834492],
      [-73.64119720458983, 10.615400314331076],
      [-73.63770294189453, 10.596899986267115],
      [-73.63369750976562, 10.581800460815456],
      [-73.62960052490233, 10.565600395202662],
      [-73.62729644775389, 10.545399665832544],
      [-73.62729644775389, 10.528100013732937],
      [-73.62670135498047, 10.514800071716335],
      [-73.64340209960938, 10.505000114440945],
      [-73.65670013427734, 10.503899574279814],
      [-73.66649627685547, 10.489600181579616],
      [-73.67220306396483, 10.473999977111841],
      [-73.68260192871094, 10.45899963378909],
      [-73.69409942626953, 10.449199676513697],
      [-73.70800018310547, 10.446399688720732],
      [-73.7281036376953, 10.443599700927765],
      [-73.73509979248047, 10.443599700927766],
      [-73.74369812011719, 10.43439960479739],
      [-73.74250030517577, 10.418199539184597],
      [-73.74140167236327, 10.412500381469757],
      [-73.74710083007812, 10.40789985656741],
      [-73.75460052490234, 10.408499717712429],
      [-73.76619720458984, 10.411999702453643],
      [-73.7748031616211, 10.39869976043704],
      [-73.78630065917969, 10.393600463867214],
      [-73.7960968017578, 10.394200325012235],
      [-73.8030014038086, 10.391900062561067],
      [-73.81400299072266, 10.383899688720732],
      [-73.83760070800781, 10.381099700927763],
      [-73.85379791259766, 10.380599975585968],
      [-73.86990356445312, 10.381199836730989],
      [-73.88839721679688, 10.383000373840366],
      [-73.90679931640625, 10.37909984588626],
      [-73.93450164794922, 10.360099792480497],
      [-73.95809936523436, 10.338899612426786],
      [-73.96959686279297, 10.319299697876007],
      [-73.97640228271484, 10.311902999877965],
      [-74.01747131347656, 10.365605354309112],
      [-74.04080963134764, 10.349264144897491],
      [-74.18800354003906, 10.380647659301786],
      [-74.18810272216797, 10.38199996948245],
      [-74.20020294189453, 10.408599853515653],
      [-74.20490264892578, 10.428799629211452],
      [-74.21289825439453, 10.44909954071048],
      [-74.2135009765625, 10.46070003509524],
      [-74.21240234375, 10.475099563598658],
      [-74.21179962158203, 10.496500015258816],
      [-74.21179962158203, 10.517299652099634],
    ],
    id: "1",
  },
  {
    name: "Plato",
    location: {
      longitude: "-74.6030297",
      latitude: "9.7900323",
    },
    image:'plato.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/6",
    population: "48.898 habitantes",
    sites: [
      {
        name: [
          "Museo comunitario de San Jacinto",
          "La santa",
          "Iglesia Santa Bárbara",
          "Cerro la Cansona",
          "Travel Palenque",
          "Palenque: Tour Raíces",
        ],
      },
    ],
    polygon: [
      [-74.56199645996094, 9.94509983062748],
      [-74.55560302734375, 9.959600448608438],
      [-74.55799865722656, 9.968799591064492],
      [-74.55909729003906, 9.984999656677287],
      [-74.55970001220703, 10.001199722290075],
      [-74.560302734375, 10.010999679565467],
      [-74.54930114746094, 10.013199806213416],
      [-74.53720092773438, 10.010299682617221],
      [-74.52339935302734, 10.009699821472209],
      [-74.50550079345703, 10.013099670410194],
      [-74.48190307617188, 10.01350021362308],
      [-74.46060180664062, 10.002499580383338],
      [-74.43920135498047, 9.998299598693887],
      [-74.43370819091795, 9.998549461364783],
      [-74.39210510253906, 9.984791755676307],
      [-74.37117004394531, 9.971592903137246],
      [-74.33795166015625, 9.936093330383342],
      [-74.34673309326172, 9.93346118927006],
      [-74.34991455078125, 9.925504684448281],
      [-74.3568115234375, 9.917549133300819],
      [-74.36582946777344, 9.919139862060588],
      [-74.37590789794922, 9.923383712768594],
      [-74.38704681396484, 9.933991432189982],
      [-74.39605712890625, 9.948311805725137],
      [-74.40879058837889, 9.954146385192908],
      [-74.4109115600586, 9.931339263916056],
      [-74.4103775024414, 9.912775993347209],
      [-74.41303253173828, 9.894742012023967],
      [-74.43265533447266, 9.877769470214883],
      [-74.4549331665039, 9.86026668548588],
      [-74.47615051269531, 9.839581489563031],
      [-74.4666061401367, 9.831094741821332],
      [-74.44803619384766, 9.819956779480023],
      [-74.4416732788086, 9.803514480590861],
      [-74.42788696289062, 9.778585433960005],
      [-74.42894744873047, 9.763734817504929],
      [-74.43531036376953, 9.719712257385298],
      [-74.43477630615234, 9.695843696594284],
      [-74.43849182128906, 9.657655715942429],
      [-74.44697570800781, 9.643335342407271],
      [-74.4708480834961, 9.630075454711964],
      [-74.4856948852539, 9.618406295776413],
      [-74.48357391357422, 9.61310291290288],
      [-74.48165893554688, 9.600860595703171],
      [-74.50240325927734, 9.592000007629442],
      [-74.528564453125, 9.540646553039597],
      [-74.54063415527344, 9.602128982543993],
      [-74.5579833984375, 9.621744155883837],
      [-74.58250427246094, 9.654560089111374],
      [-74.59532928466797, 9.67040252685551],
      [-74.6032485961914, 9.670024871826218],
      [-74.63795471191406, 9.64550685882573],
      [-74.66095733642578, 9.649656295776413],
      [-74.68811798095705, 9.626646995544478],
      [-74.66699218750001, 9.61985778808598],
      [-74.66020965576172, 9.602884292602583],
      [-74.67076873779298, 9.576857566833544],
      [-74.72395324707031, 9.548944473266648],
      [-74.75035858154298, 9.556488990783738],
      [-74.78769683837892, 9.516900062561083],
      [-74.80960083007814, 9.516400337219284],
      [-74.80670166015626, 9.527400016784716],
      [-74.81079864501955, 9.541799545288134],
      [-74.80850219726564, 9.552200317382862],
      [-74.79699707031251, 9.559700012207076],
      [-74.7894973754883, 9.56949996948247],
      [-74.78430175781251, 9.583900451660204],
      [-74.78140258789062, 9.585599899292037],
      [-74.77739715576172, 9.600600242614794],
      [-74.78780364990236, 9.611599922180222],
      [-74.79640197753908, 9.621500015258835],
      [-74.81079864501955, 9.637700080871628],
      [-74.81890106201175, 9.652199745178269],
      [-74.82640075683597, 9.660300254821824],
      [-74.83219909667972, 9.666099548339892],
      [-74.84079742431643, 9.679400444030806],
      [-74.84660339355472, 9.690999984741252],
      [-74.84549713134768, 9.705499649047894],
      [-74.84079742431643, 9.712400436401412],
      [-74.83159637451175, 9.72620010375981],
      [-74.8246994018555, 9.741800308227582],
      [-74.82420349121097, 9.752200126647995],
      [-74.82299804687501, 9.761400222778365],
      [-74.82879638671878, 9.772399902343794],
      [-74.83339691162112, 9.781700134277388],
      [-74.83969879150393, 9.788000106811564],
      [-74.80570220947267, 9.794799804687544],
      [-74.78559875488281, 9.799900054931683],
      [-74.76879882812501, 9.806799888610879],
      [-74.76419830322267, 9.820099830627484],
      [-74.7487030029297, 9.817700386047404],
      [-74.73370361328126, 9.823399543762246],
      [-74.73660278320312, 9.830900192260781],
      [-74.73370361328126, 9.840700149536174],
      [-74.71810150146486, 9.843000411987346],
      [-74.7020034790039, 9.854499816894574],
      [-74.69110107421876, 9.855600357055703],
      [-74.66860198974611, 9.860099792480511],
      [-74.65010070800781, 9.8634996414185],
      [-74.63569641113281, 9.87790012359623],
      [-74.6281967163086, 9.878999710083049],
      [-74.6178970336914, 9.869099617004432],
      [-74.60749816894531, 9.875399589538613],
      [-74.5959014892578, 9.87650012969975],
      [-74.58149719238281, 9.870699882507363],
      [-74.57170104980469, 9.876399993896525],
      [-74.56999969482422, 9.8851003646851],
      [-74.56430053710938, 9.902999877929727],
      [-74.5636978149414, 9.909899711608928],
      [-74.55509948730469, 9.930100440979045],
      [-74.56199645996094, 9.94509983062748],
    ],
    id: "2",
  },
  {
    name: "Ariguaní",
    location: {
      longitude: "-74.0932294",
      latitude: "9.857023",
    },
    image:'ariguani.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/7",
    population: "30.568 habitantes",
    sites: [
      {
        name: [
          "Iglesia Santa Bárbara",
          "Itti Takke Zona Recreacional",
          "Iglesia San Francisco",
          "Plaza de Santo Domingo",
          "Iglesia de la Inmaculada Concepción",
          "Cementerio de Mompox",
        ],
      },
    ],
    polygon: [
      [-74.06449890136717, 9.98579978942875],
      [-74.05180358886717, 9.97360038757328],
      [-74.03160095214842, 9.957400321960488],
      [-74.03160095214842, 9.949299812316932],
      [-74.02300262451172, 9.931900024414103],
      [-73.9999008178711, 9.916799545288129],
      [-73.98670196533203, 9.911499977111857],
      [-73.98099517822266, 9.90600490570072],
      [-73.95960235595703, 9.885399818420451],
      [-73.95149993896484, 9.864000320434608],
      [-73.9365005493164, 9.842000007629439],
      [-73.92549896240234, 9.823499679565469],
      [-73.91169738769531, 9.811300277710004],
      [-73.88569641113281, 9.796199798584025],
      [-73.88169860839844, 9.791500091552777],
      [-73.87989807128906, 9.789799690246628],
      [-73.87650299072266, 9.784000396728556],
      [-73.87419891357422, 9.781700134277386],
      [-73.86260223388672, 9.773500442504929],
      [-73.86150360107422, 9.7630996704102],
      [-73.86489868164062, 9.748100280761761],
      [-73.86644744873047, 9.73726558685307],
      [-73.86720275878906, 9.732000350952191],
      [-73.86090087890625, 9.71920013427739],
      [-73.85859680175781, 9.713999748230027],
      [-73.8718032836914, 9.706600189209029],
      [-73.88510131835938, 9.704299926757857],
      [-73.89600372314453, 9.706100463867232],
      [-73.9093017578125, 9.709600448608445],
      [-73.9156036376953, 9.713100433349656],
      [-73.93470001220703, 9.713199615478564],
      [-73.96410369873047, 9.713899612426802],
      [-73.98310089111327, 9.720899581909226],
      [-74.00559997558594, 9.721599578857466],
      [-74.02690124511719, 9.722200393676802],
      [-74.0447998046875, 9.722299575805705],
      [-74.06320190429688, 9.722399711608928],
      [-74.08450317382812, 9.718400001525922],
      [-74.10009765625, 9.711000442504927],
      [-74.11280059814453, 9.70119953155522],
      [-74.12139892578125, 9.685700416564988],
      [-74.13120269775389, 9.667200088501026],
      [-74.13980102539062, 9.65110015869145],
      [-74.15190124511717, 9.639599800109908],
      [-74.15889739990234, 9.63099956512456],
      [-74.17150115966797, 9.618300437927294],
      [-74.18360137939453, 9.605600357055708],
      [-74.18769836425781, 9.593500137329146],
      [-74.20960235595703, 9.586099624633837],
      [-74.1868896484375, 9.616853713989306],
      [-74.1772232055664, 9.644603729248093],
      [-74.17806243896484, 9.679922103881884],
      [-74.1961441040039, 9.705569267272994],
      [-74.21548461914061, 9.740887641906783],
      [-74.24701690673828, 9.764012336731003],
      [-74.2684555053711, 9.774944305419966],
      [-74.27476501464844, 9.803114891052289],
      [-74.27476501464844, 9.815728187561078],
      [-74.27056121826172, 9.82960319519047],
      [-74.26972198486328, 9.843898773193404],
      [-74.27518463134766, 9.857353210449258],
      [-74.27149963378906, 9.882843971252482],
      [-74.26285552978516, 9.938823699951213],
      [-74.25420379638672, 9.974323272705119],
      [-74.22462463378906, 9.987977027893105],
      [-74.17774200439452, 9.985246658325234],
      [-74.1513442993164, 9.96294593811039],
      [-74.14360809326172, 9.955663681030316],
      [-74.1386032104492, 9.956573486328164],
      [-74.13359832763672, 9.967951774597209],
      [-74.12540435791016, 9.970227241516154],
      [-74.11083984375, 9.970682144165076],
      [-74.09855651855469, 9.983426094055215],
      [-74.08444213867188, 9.989797592163127],
      [-74.0726089477539, 9.989797592163127],
      [-74.06449890136717, 9.98579978942875],
    ],
    id: "3",
  },
  {
    name: "Zona Bananera",
    location: {
        longitude: "-74.1770207",
        latitude: "10.793461",
    },
    image:'zonabananera.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/8",
    population: "56.404 habitantes",
    sites: [
      {
        name: [
          "The Gypsy Residence Tours",
          "Casa Museo Gabriel García Márquez",
          "Iglesia San José",
          "Playa Cristal",
          "Parque Nacional Natural Sierra Nevada de Santa Marta",
          "Cascadas de Marinka",
        ],
      },
    ],
    polygon: [
      [-74.30271911621094, 10.934912681579604],
      [-74.2840347290039, 10.951521873474132],
      [-74.27179718017578, 10.957637786865247],
      [-74.25712585449219, 10.960083961486827],
      [-74.24488830566406, 10.960083961486829],
      [-74.23877716064453, 10.95886039733888],
      [-74.22776794433594, 10.960083961486827],
      [-74.22654724121094, 10.969868659973157],
      [-74.2253189086914, 10.97476100921632],
      [-74.2253189086914, 10.982099533081065],
      [-74.22042846679688, 10.986991882324228],
      [-74.21675872802734, 10.99555301666261],
      [-74.21064758300781, 10.999222755432141],
      [-74.20452880859375, 11.000445365905769],
      [-74.19963836669922, 11.001668930053722],
      [-74.19474792480469, 11.00166893005372],
      [-74.1861801147461, 10.99066066741944],
      [-74.1837387084961, 10.985768318176282],
      [-74.18128967285156, 10.979653358459485],
      [-74.17761993408203, 10.972314834594737],
      [-74.16905975341797, 10.97353744506837],
      [-74.16416931152344, 10.978429794311534],
      [-74.15560913085938, 10.979653358459485],
      [-74.14459991455078, 10.97353744506837],
      [-74.14459991455078, 10.960083961486829],
      [-74.14459991455078, 10.951521873474135],
      [-74.14704132080078, 10.942960739135753],
      [-74.14704132080078, 10.935622215271005],
      [-74.14582061767578, 10.929506301879897],
      [-74.14459991455078, 10.925838470458995],
      [-74.13726043701172, 10.925838470458997],
      [-74.12992095947266, 10.938068389892596],
      [-74.12747192382812, 10.94051456451417],
      [-74.12503051757812, 10.942960739135753],
      [-74.12380981445312, 10.944183349609384],
      [-74.1201400756836, 10.944183349609386],
      [-74.1201400756836, 10.94051456451417],
      [-74.1201400756836, 10.93439865112306],
      [-74.11769104003906, 10.928283691406264],
      [-74.11769104003906, 10.923391342163098],
      [-74.11647033691406, 10.918498992919934],
      [-74.111572265625, 10.907490730285659],
      [-74.109130859375, 10.898929595947282],
      [-74.10546112060547, 10.890368461608901],
      [-74.10301208496094, 10.883028984069842],
      [-74.10301208496094, 10.876914024353043],
      [-74.0969009399414, 10.87079811096193],
      [-74.09200286865234, 10.861013412475604],
      [-74.08833312988281, 10.853674888610854],
      [-74.08589172363281, 10.846336364746106],
      [-74.08589172363281, 10.838997840881364],
      [-74.08711242675781, 10.835329055786147],
      [-74.08467102050781, 10.826766967773452],
      [-74.08711242675781, 10.814536094665542],
      [-74.08467102050781, 10.805974960327163],
      [-74.08100128173828, 10.796190261840835],
      [-74.08100128173828, 10.788851737976094],
      [-74.08344268798828, 10.782735824584979],
      [-74.07855224609375, 10.774174690246602],
      [-74.07855224609375, 10.763166427612322],
      [-74.07488250732422, 10.749711990356465],
      [-74.07366180419922, 10.736259460449237],
      [-74.07488250732422, 10.726473808288594],
      [-74.07243347167969, 10.711796760559102],
      [-74.07488250732422, 10.692227363586445],
      [-74.07121276855467, 10.677550315856953],
      [-74.07121276855467, 10.665319442749047],
      [-74.07379913330078, 10.644623756408713],
      [-74.07469940185545, 10.643799781799338],
      [-74.08619689941406, 10.637999534606953],
      [-74.09660339355467, 10.612700462341328],
      [-74.09950256347656, 10.60690021514895],
      [-74.11160278320312, 10.601200103759792],
      [-74.15190124511717, 10.607099533081078],
      [-74.17729949951172, 10.617600440979027],
      [-74.1957015991211, 10.628700256347678],
      [-74.20960235595703, 10.637399673461935],
      [-74.22339630126953, 10.641500473022484],
      [-74.22979736328125, 10.64319992065432],
      [-74.23320007324219, 10.63860034942629],
      [-74.2354965209961, 10.64379978179934],
      [-74.23670196533203, 10.657699584960959],
      [-74.2406997680664, 10.668100357055684],
      [-74.24189758300781, 10.676199913024922],
      [-74.24539947509766, 10.684300422668477],
      [-74.2552032470703, 10.706899642944352],
      [-74.25749969482422, 10.711500167846696],
      [-74.25980377197266, 10.72889995574953],
      [-74.26609802246094, 10.750800132751483],
      [-74.27539825439453, 10.783200263977067],
      [-74.27890014648438, 10.806300163269057],
      [-74.28520202636719, 10.8276996612549],
      [-74.29329681396484, 10.845700263977067],
      [-74.30310058593749, 10.857899665832536],
      [-74.3134994506836, 10.864800453186051],
      [-74.31809997558594, 10.869999885559096],
      [-74.31179809570312, 10.879300117492688],
      [-74.31120300292969, 10.891400337219252],
      [-74.31120300292969, 10.90699958801271],
      [-74.30770111083984, 10.924900054931653],
      [-74.30271911621094, 10.934912681579604],
    ],
    id: "4",
  },
  {
    name: "Pueblo viejo",
    location: {
      longitude: "-74.3500507",
      latitude: "10.802654",
    },
    image:'puebloviejo.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/9",
    population: "33.720 habitantes",
    sites: [
      {
        name: [
          "Rodadero Playa Salguero",
          "Colombia Luxury Travel",
          "Kayak Club and Hostel Beach Lovers",
          "Aquarius Diving Club",
          "Caribbean Sailing School",
          "Paddle Santa Marta",
        ],
      },
    ],
    polygon: [
      [-74.3031997680664, 10.987199783325206],
      [-74.30030059814453, 10.972800254821788],
      [-74.29570007324219, 10.957799911499036],
      [-74.29679870605469, 10.946800231933606],
      [-74.30271911621094, 10.934912681579604],
      [-74.30770111083984, 10.924900054931653],
      [-74.31120300292969, 10.90699958801271],
      [-74.31120300292969, 10.891400337219252],
      [-74.31179809570312, 10.879300117492688],
      [-74.31809997558594, 10.869999885559096],
      [-74.3134994506836, 10.864800453186051],
      [-74.30310058593749, 10.857899665832536],
      [-74.29329681396484, 10.845700263977067],
      [-74.28520202636719, 10.8276996612549],
      [-74.27890014648438, 10.806300163269057],
      [-74.27539825439453, 10.783200263977067],
      [-74.26609802246094, 10.750800132751483],
      [-74.25980377197266, 10.72889995574953],
      [-74.25749969482422, 10.711500167846696],
      [-74.2552032470703, 10.706899642944352],
      [-74.24539947509766, 10.684300422668477],
      [-74.24189758300781, 10.676199913024922],
      [-74.2406997680664, 10.668100357055684],
      [-74.23670196533203, 10.657699584960959],
      [-74.2354965209961, 10.64379978179934],
      [-74.23320007324219, 10.63860034942629],
      [-74.2499008178711, 10.635199546813988],
      [-74.26090240478516, 10.640500068664574],
      [-74.27700042724608, 10.657899856567402],
      [-74.2836685180664, 10.674115180969258],
      [-74.28510284423828, 10.67759990692141],
      [-74.28970336914062, 10.69200038909914],
      [-74.29959869384766, 10.707099914550803],
      [-74.30590057373047, 10.719200134277362],
      [-74.31169891357422, 10.726200103759785],
      [-74.33930206298828, 10.71940040588381],
      [-74.34510040283202, 10.71940040588381],
      [-74.35379791259766, 10.728699684143086],
      [-74.36930084228516, 10.73910045623781],
      [-74.3843002319336, 10.738599777221697],
      [-74.3929977416992, 10.730600357055684],
      [-74.39990234375, 10.727100372314473],
      [-74.41600036621092, 10.723199844360368],
      [-74.42929840087889, 10.72029972076418],
      [-74.44190216064453, 10.716899871826193],
      [-74.44830322265625, 10.716400146484395],
      [-74.46150207519531, 10.704899787902853],
      [-74.4655990600586, 10.711799621582053],
      [-74.4655990600586, 10.719300270080584],
      [-74.4655990600586, 10.726799964904803],
      [-74.4655990600586, 10.737199783325213],
      [-74.46730041503906, 10.74820041656496],
      [-74.47019958496092, 10.763799667358418],
      [-74.47309875488281, 10.776000022888201],
      [-74.47709655761719, 10.788700103759782],
      [-74.4822998046875, 10.808400154113787],
      [-74.48290252685547, 10.818799972534196],
      [-74.48699951171875, 10.830300331115735],
      [-74.48930358886719, 10.838399887084979],
      [-74.49909973144531, 10.854599952697765],
      [-74.50659942626953, 10.862199783325213],
      [-74.5094985961914, 10.870300292968762],
      [-74.51930236816405, 10.8916997909546],
      [-74.52279663085938, 10.909000396728528],
      [-74.51580047607422, 10.922300338745131],
      [-74.50659942626953, 10.9586000442505],
      [-74.50260162353516, 10.958600044250499],
      [-74.49629974365233, 10.959199905395522],
      [-74.48470306396483, 10.956800460815442],
      [-74.46800231933594, 10.954999923706067],
      [-74.46050262451172, 10.954999923706069],
      [-74.44029998779297, 10.95549964904786],
      [-74.42310333251953, 10.956000328063972],
      [-74.4092025756836, 10.95709991455079],
      [-74.38729858398438, 10.960399627685558],
      [-74.36830139160156, 10.96269989013673],
      [-74.3572998046875, 10.9654998779297],
      [-74.3447036743164, 10.97179985046388],
      [-74.33260345458984, 10.972900390625014],
      [-74.32099914550781, 10.974599838256847],
      [-74.3031997680664, 10.987199783325206],
    ],
    id: "5",
  },
  {
    name: "San Sebastián de Buena vista",
    location: {
      longitude: "-74.1399483",
      latitude: "9.4103222",
    },
    image:'sansebastiandebuenavista.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/10",
    population: "18.865 habitantes",
    sites: [{ name: ["Itti Takke Zona Recreacional", "La Piedra de Bolivar", "Casa Museo Luis Guillermo Trespalacios", "Plaza de mercado", "Iglesia San Francisco", "Mercado municipal"] }],
    polygon: [
      [-74.2020034790039, 9.463600158691454],
      [-74.19509887695312, 9.464200019836474],
      [-74.18240356445312, 9.469300270080614],
      [-74.16739654541016, 9.473299980163624],
      [-74.15699768066406, 9.474399566650439],
      [-74.15632629394531, 9.477104187011767],
      [-74.14839935302734, 9.46689987182622],
      [-74.10169982910156, 9.444100379943897],
      [-74.08609771728516, 9.43190002441411],
      [-74.07520294189453, 9.418600082397509],
      [-74.0625, 9.40530014038091],
      [-74.04630279541016, 9.387299537658743],
      [-74.04109954833984, 9.378000259399462],
      [-74.04509735107422, 9.36999988555913],
      [-74.0624008178711, 9.369500160217335],
      [-74.0802993774414, 9.360300064086962],
      [-74.09590148925781, 9.347100257873588],
      [-74.11720275878906, 9.32979965209966],
      [-74.13500213623047, 9.327599525451712],
      [-74.15640258789062, 9.325400352478075],
      [-74.18060302734375, 9.321999549865776],
      [-74.19730377197266, 9.313400268554739],
      [-74.21749877929688, 9.306599617004444],
      [-74.22029876708984, 9.295599937439016],
      [-74.21630096435545, 9.278300285339407],
      [-74.21920013427734, 9.267900466919],
      [-74.22260284423828, 9.266200065612844],
      [-74.23529815673828, 9.26329994201665],
      [-74.25379943847656, 9.251299858093315],
      [-74.26300048828125, 9.234600067138725],
      [-74.2698974609375, 9.220199584960989],
      [-74.28720092773438, 9.18959999084478],
      [-74.32119750976561, 9.18799972534185],
      [-74.32520294189453, 9.190299987793026],
      [-74.34480285644531, 9.198499679565483],
      [-74.35459899902344, 9.204299926757864],
      [-74.36380004882811, 9.208999633789116],
      [-74.38050079345703, 9.206199645996143],
      [-74.39209747314453, 9.202799797058157],
      [-74.408203125, 9.198800086975151],
      [-74.41220092773438, 9.196499824523979],
      [-74.42489624023438, 9.191900253295952],
      [-74.43240356445312, 9.197699546814018],
      [-74.43190002441405, 9.205800056457573],
      [-74.3979034423828, 9.23219966888433],
      [-74.38459777832031, 9.23849964141851],
      [-74.37539672851561, 9.244899749755913],
      [-74.35060119628906, 9.258000373840387],
      [-74.32810211181639, 9.271800041198784],
      [-74.3114013671875, 9.288499832153374],
      [-74.28260040283203, 9.304499626159718],
      [-74.26709747314453, 9.320099830627493],
      [-74.2573013305664, 9.333299636840868],
      [-74.24749755859375, 9.360400199890185],
      [-74.22899627685545, 9.388600349426321],
      [-74.21179962158203, 9.412300109863331],
      [-74.20140075683594, 9.429499626159718],
      [-74.20140075683594, 9.451499938964893],
      [-74.2020034790039, 9.463600158691454],
    ],
    id: "6",
  },
  {
    name: "Sitio Nuevo",
    location: {
      longitude: "-74.6008077",
      latitude: "10.872975",
    },
    image:'sitionuevo.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/11",
    population: "33.440 habitantes",
    sites: [{ name: ["Iglesia Santa Maria Magdalena", "Centro comercial Carnaval", "Teatro Rex", "Plaza San Nicolás", "Parque Washington", "Antiguo edificio de la Aduana"] }],
    polygon: [
      [-74.50659942626953, 10.9586000442505],
      [-74.51580047607422, 10.922300338745131],
      [-74.52279663085938, 10.909000396728528],
      [-74.51930236816405, 10.8916997909546],
      [-74.5094985961914, 10.870300292968762],
      [-74.50659942626953, 10.862199783325213],
      [-74.49909973144531, 10.854599952697765],
      [-74.48930358886719, 10.838399887084979],
      [-74.48699951171875, 10.830300331115735],
      [-74.48290252685547, 10.818799972534196],
      [-74.4822998046875, 10.808400154113787],
      [-74.47709655761719, 10.788700103759782],
      [-74.47309875488281, 10.776000022888201],
      [-74.47019958496092, 10.763799667358418],
      [-74.46730041503906, 10.74820041656496],
      [-74.4655990600586, 10.737199783325213],
      [-74.4655990600586, 10.726799964904803],
      [-74.4655990600586, 10.719300270080584],
      [-74.4655990600586, 10.711799621582053],
      [-74.46150207519531, 10.704899787902853],
      [-74.46959686279297, 10.705499649047868],
      [-74.48290252685547, 10.711899757385272],
      [-74.50189971923828, 10.71490001678469],
      [-74.51570129394531, 10.7184000015259],
      [-74.52259826660156, 10.72189998626711],
      [-74.53589630126953, 10.725999832153338],
      [-74.54920196533203, 10.730699539184592],
      [-74.56529998779297, 10.727800369262713],
      [-74.57450103759766, 10.722100257873553],
      [-74.6084976196289, 10.715299606323264],
      [-74.6218032836914, 10.711899757385273],
      [-74.6344985961914, 10.710800170898457],
      [-74.65699768066406, 10.70919990539553],
      [-74.67479705810547, 10.70810031890871],
      [-74.6875, 10.707500457763693],
      [-74.6967010498047, 10.709899902343766],
      [-74.70770263671876, 10.712300300598166],
      [-74.74340057373047, 10.710700035095236],
      [-74.73999786376955, 10.715299606323262],
      [-74.74400329589844, 10.729700088500996],
      [-74.74400329589844, 10.736700057983418],
      [-74.74749755859376, 10.744799613952653],
      [-74.74400329589844, 10.750499725341816],
      [-74.74690246582033, 10.75809955596926],
      [-74.74919891357423, 10.765000343322772],
      [-74.74859619140626, 10.77130031585695],
      [-74.75039672851562, 10.779399871826191],
      [-74.75039672851562, 10.789299964904801],
      [-74.74810028076173, 10.800200462341325],
      [-74.74810028076173, 10.81120014190676],
      [-74.74749755859376, 10.822199821472184],
      [-74.74639892578126, 10.827899932861346],
      [-74.74179840087892, 10.841799736022965],
      [-74.74349975585939, 10.850999832153336],
      [-74.74349975585939, 10.85910034179689],
      [-74.74120330810548, 10.866600036621106],
      [-74.73889923095705, 10.87180042266847],
      [-74.7320022583008, 10.876999855041518],
      [-74.73139953613283, 10.882800102233901],
      [-74.73490142822266, 10.889100074768079],
      [-74.74349975585939, 10.895500183105481],
      [-74.75039672851562, 10.900199890136733],
      [-74.75679779052736, 10.904199600219739],
      [-74.76309967041017, 10.909500122070325],
      [-74.76660156250001, 10.915800094604506],
      [-74.77239990234376, 10.922800064086926],
      [-74.77410125732423, 10.927399635314956],
      [-74.77488708496095, 10.93157863616945],
      [-74.77529907226564, 10.933799743652358],
      [-74.77469635009767, 10.93900012969972],
      [-74.77469635009767, 10.94880008697511],
      [-74.77469635009767, 10.958600044250499],
      [-74.77469635009767, 10.970199584960948],
      [-74.79139709472658, 10.990400314331064],
      [-74.80069732666017, 11.004899978637704],
      [-74.80930328369142, 11.01770019531251],
      [-74.82140350341798, 11.024100303649911],
      [-74.82890319824222, 11.030500411987314],
      [-74.83640289306642, 11.040300369262704],
      [-74.83942413330081, 11.046132087707528],
      [-74.84329986572268, 11.053600311279306],
      [-74.84799957275392, 11.064000129699712],
      [-74.85257720947267, 11.072692871093759],
      [-74.84619903564456, 11.073800086975105],
      [-74.8352966308594, 11.069199562072763],
      [-74.81680297851564, 11.065099716186532],
      [-74.8018035888672, 11.063300132751474],
      [-74.79029846191408, 11.059200286865243],
      [-74.75, 11.048600196838386],
      [-74.72799682617188, 11.038100242614751],
      [-74.69689941406251, 11.025300025939949],
      [-74.67559814453125, 11.015899658203136],
      [-74.63980102539062, 11.004199981689462],
      [-74.614501953125, 10.990300178527841],
      [-74.59200286865234, 10.979800224304208],
      [-74.56890106201172, 10.972200393676767],
      [-74.5354995727539, 10.961099624633798],
      [-74.50659942626953, 10.9586000442505],
    ],
    id: "7",
  },
  {
    name: "Salamina",
    location: {
      longitude: "-74.7024827",
      latitude: "10.512875",
    },
    image:'salamina.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/12",
    population: "8.239 Habitantes",
    sites: [{ name: ["Bosque De Palma De Cera La Samaria", "Centro histórico", "Iglesia de San Felix", "Café entre Montañas", "Cacique Pipinta", "Pueblito Viejo"] }],
    polygon: [
      [-74.677001953125, 10.543499946594261],
      [-74.67130279541017, 10.535300254821804],
      [-74.67299652099608, 10.519200325012232],
      [-74.67299652099608, 10.505900382995634],
      [-74.66609954833984, 10.491399765014673],
      [-74.66490173339844, 10.478699684143091],
      [-74.66780090332031, 10.470100402832058],
      [-74.66829681396486, 10.456199645996117],
      [-74.66741180419923, 10.445382118225123],
      [-74.67060089111328, 10.446999549865751],
      [-74.68270111083984, 10.442399978637724],
      [-74.69539642333984, 10.439000129699734],
      [-74.70580291748048, 10.436100006103544],
      [-74.72080230712892, 10.433899879455595],
      [-74.73870086669923, 10.434000015258816],
      [-74.75879669189455, 10.441599845886257],
      [-74.77089691162111, 10.44680023193362],
      [-74.78070068359376, 10.44859981536868],
      [-74.82740020751956, 10.453399658203152],
      [-74.81880187988283, 10.463800430297878],
      [-74.81300354003908, 10.476400375366236],
      [-74.79579925537111, 10.500599861145044],
      [-74.77439880371095, 10.521900177001978],
      [-74.76059722900392, 10.535699844360376],
      [-74.7468032836914, 10.548999786376978],
      [-74.74330139160158, 10.562800407409695],
      [-74.7427978515625, 10.573200225830101],
      [-74.7427978515625, 10.579000473022482],
      [-74.71859741210939, 10.579400062561056],
      [-74.7042007446289, 10.585200309753441],
      [-74.68460083007814, 10.582200050354023],
      [-74.68170166015626, 10.565999984741236],
      [-74.67880249023438, 10.553299903869654],
      [-74.677001953125, 10.543499946594261],
    ],
    id: "8",
  },
  {
    name: "Algarrobo",
    location: {
      longitude: "-74.1217447",
      latitude: "10.27162",
    },
    image:'algarrobo.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/13",
    population: "11.556 habitantes",
    sites: [{ name: ["Mueos Pablo Neruda", "Isla Negra", "La Sebastiana", "Viña Casas del Bosque", "San Alfonso del Mar", "Pomaire"] }],
    polygon: [
      [-74.04080963134764, 10.349264144897491],
      [-74.01747131347656, 10.365605354309112],
      [-73.97640228271484, 10.311902999877965],
      [-73.9833984375, 10.304300308227571],
      [-73.98750305175781, 10.295700073242221],
      [-73.99210357666016, 10.281800270080597],
      [-73.99720001220703, 10.267399787902862],
      [-74.00879669189453, 10.248999595642124],
      [-74.02030181884766, 10.239199638366733],
      [-74.02950286865234, 10.238100051879917],
      [-74.03810119628906, 10.215000152587923],
      [-74.04560089111328, 10.20289993286136],
      [-74.0634994506836, 10.201800346374545],
      [-74.07160186767578, 10.18739986419681],
      [-74.0698013305664, 10.17240047454837],
      [-74.07730102539061, 10.159700393676795],
      [-74.08480072021484, 10.153400421142612],
      [-74.09400177001953, 10.136699676513707],
      [-74.10260009765625, 10.105500221252475],
      [-74.10549926757812, 10.087599754333533],
      [-74.1083984375, 10.076100349426309],
      [-74.11064910888672, 10.066512107849157],
      [-74.12201690673828, 10.10808658599857],
      [-74.12201690673828, 10.115186691284215],
      [-74.12395477294922, 10.131001472473178],
      [-74.12911987304688, 10.146170616149934],
      [-74.13912200927734, 10.165535926818883],
      [-74.14622497558594, 10.178123474121128],
      [-74.14589691162108, 10.18974113464359],
      [-74.15977478027344, 10.19490528106693],
      [-74.17236328124999, 10.185222625732456],
      [-74.18463134765625, 10.184900283813509],
      [-74.22045135498047, 10.194259643554721],
      [-74.22270965576172, 10.207815170288121],
      [-74.25434112548828, 10.2404127120972],
      [-74.26047515869139, 10.248481750488315],
      [-74.26302337646484, 10.25139045715335],
      [-74.2555694580078, 10.260559082031282],
      [-74.2088851928711, 10.332923889160188],
      [-74.18800354003906, 10.380647659301786],
      [-74.04080963134764, 10.349264144897491],
    ],
    id: "9",
  },
  {
    name: "Ciénaga",
    location: {
      longitude: "-73.9944217",
      latitude: "10.886468",
    },
    image:'cienaga.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/2",
    population: "100.908 habitantes",
    sites: [{ name: ["Plaza Centenario", "Iguan tours", "Paddle Santa Marta", "La Casa d' Remedios la Bella Hotel Boutique", "Hotel Topo Real", " Los Caciques"] }],
    polygon: [
      [-74.25019836425781, 11.093899726867683],
      [-74.2074966430664, 11.089099884033208],
      [-74.19830322265625, 11.097700119018562],
      [-74.18509674072266, 11.100000381469735],
      [-74.17530059814453, 11.086600303649911],
      [-74.1603012084961, 11.08250045776368],
      [-74.14240264892578, 11.084799766540536],
      [-74.12860107421875, 11.0811996459961],
      [-74.12159729003906, 11.069100379943853],
      [-74.11009979248047, 11.0545997619629],
      [-74.0968017578125, 11.041799545288093],
      [-74.0801010131836, 11.037099838256845],
      [-74.06919860839844, 11.033599853515632],
      [-74.068603515625, 11.027299880981456],
      [-74.06510162353514, 11.01799964904786],
      [-74.05239868164062, 11.01329994201661],
      [-74.03919982910156, 11.01329994201661],
      [-74.01609802246094, 11.013199806213388],
      [-74.00630187988281, 11.006799697875987],
      [-73.99710083007812, 10.999799728393565],
      [-73.97579956054688, 10.999699592590337],
      [-73.95790100097656, 10.99969959259034],
      [-73.95099639892578, 10.99149990081788],
      [-73.95210266113281, 10.978300094604506],
      [-73.95149993896484, 10.962699890136731],
      [-73.9446029663086, 10.947600364685073],
      [-73.93360137939453, 10.919300079345714],
      [-73.91570281982422, 10.90069961547853],
      [-73.90480041503905, 10.882200241088883],
      [-73.87770080566406, 10.862999916076673],
      [-73.86209869384766, 10.85369968414308],
      [-73.84940338134766, 10.851300239563002],
      [-73.8407974243164, 10.850700378417983],
      [-73.83560180664061, 10.850099563598645],
      [-73.81369781494139, 10.850000381469743],
      [-73.81939697265625, 10.818900108337417],
      [-73.84190368652344, 10.793000221252457],
      [-73.86260223388672, 10.775699615478533],
      [-73.87930297851561, 10.760800361633317],
      [-73.89550018310547, 10.746399879455584],
      [-73.9104995727539, 10.733200073242205],
      [-73.9386978149414, 10.722299575805684],
      [-73.95999908447266, 10.711400032043477],
      [-73.98419952392577, 10.692999839782738],
      [-74.01069641113281, 10.67759990692141],
      [-74.03839874267577, 10.665599822998066],
      [-74.05909729003906, 10.658100128173848],
      [-74.07379913330078, 10.644623756408713],
      [-74.07121276855467, 10.665319442749047],
      [-74.07121276855467, 10.677550315856953],
      [-74.07488250732422, 10.692227363586445],
      [-74.07243347167969, 10.711796760559102],
      [-74.07488250732422, 10.726473808288594],
      [-74.07366180419922, 10.736259460449237],
      [-74.07488250732422, 10.749711990356465],
      [-74.07855224609375, 10.763166427612322],
      [-74.07855224609375, 10.774174690246602],
      [-74.08344268798828, 10.782735824584979],
      [-74.08100128173828, 10.788851737976094],
      [-74.08100128173828, 10.796190261840835],
      [-74.08467102050781, 10.805974960327163],
      [-74.08711242675781, 10.814536094665542],
      [-74.08467102050781, 10.826766967773452],
      [-74.08711242675781, 10.835329055786147],
      [-74.08589172363281, 10.838997840881364],
      [-74.08589172363281, 10.846336364746106],
      [-74.08833312988281, 10.853674888610854],
      [-74.09200286865234, 10.861013412475604],
      [-74.0969009399414, 10.87079811096193],
      [-74.10301208496094, 10.876914024353043],
      [-74.10301208496094, 10.883028984069842],
      [-74.10546112060547, 10.890368461608901],
      [-74.109130859375, 10.898929595947282],
      [-74.111572265625, 10.907490730285659],
      [-74.11647033691406, 10.918498992919934],
      [-74.11769104003906, 10.923391342163098],
      [-74.11769104003906, 10.928283691406264],
      [-74.1201400756836, 10.93439865112306],
      [-74.1201400756836, 10.94051456451417],
      [-74.1201400756836, 10.944183349609386],
      [-74.12380981445312, 10.944183349609384],
      [-74.12503051757812, 10.942960739135753],
      [-74.12747192382812, 10.94051456451417],
      [-74.12992095947266, 10.938068389892596],
      [-74.13726043701172, 10.925838470458997],
      [-74.14459991455078, 10.925838470458995],
      [-74.14582061767578, 10.929506301879897],
      [-74.14704132080078, 10.935622215271005],
      [-74.14704132080078, 10.942960739135753],
      [-74.14459991455078, 10.951521873474135],
      [-74.14459991455078, 10.960083961486829],
      [-74.14459991455078, 10.97353744506837],
      [-74.15560913085938, 10.979653358459485],
      [-74.16416931152344, 10.978429794311534],
      [-74.16905975341797, 10.97353744506837],
      [-74.17761993408203, 10.972314834594737],
      [-74.18128967285156, 10.979653358459485],
      [-74.1837387084961, 10.985768318176282],
      [-74.1861801147461, 10.99066066741944],
      [-74.19474792480469, 11.00166893005372],
      [-74.19963836669922, 11.001668930053722],
      [-74.20452880859375, 11.000445365905769],
      [-74.21064758300781, 10.999222755432141],
      [-74.21675872802734, 10.99555301666261],
      [-74.22042846679688, 10.986991882324228],
      [-74.2253189086914, 10.982099533081065],
      [-74.2253189086914, 10.97476100921632],
      [-74.22654724121094, 10.969868659973157],
      [-74.22776794433594, 10.960083961486827],
      [-74.23877716064453, 10.95886039733888],
      [-74.24488830566406, 10.960083961486829],
      [-74.25712585449219, 10.960083961486827],
      [-74.27179718017578, 10.957637786865247],
      [-74.2840347290039, 10.951521873474132],
      [-74.30271911621094, 10.934912681579604],
      [-74.29679870605469, 10.946800231933606],
      [-74.29570007324219, 10.957799911499036],
      [-74.30030059814453, 10.972800254821788],
      [-74.3031997680664, 10.987199783325206],
      [-74.30030059814453, 10.990699768066417],
      [-74.2939987182617, 10.99820041656495],
      [-74.2853012084961, 11.005599975585946],
      [-74.27030181884766, 11.019499778747567],
      [-74.2571029663086, 11.03849983215333],
      [-74.25769805908203, 11.051699638366706],
      [-74.25129699707031, 11.074199676513679],
      [-74.25019836425781, 11.093899726867683],
    ],
    id: "10",
  },
  {
    name: "Santa Marta",
    location: {
      longitude: "-73.8589857",
      latitude: "11.082768",
    },
    image:'santamarta.jpg',
    url: "https://www.tripadvisor.es/Attractions-g297484-Activities-Santa_Marta_Santa_Marta_Municipality_Magdalena_Department.html",
    population: "499.192 habitantes",
    sites: [{ name: ["Sailing to Tayrona with Luis: Unique!", "Tayrona Bay viaje en velero", "Ciudad Perdida", "Parque Nacional Tayrona", "Mica - Café y Cacao ", "Quinta de San Pedro Alejandrino"] }],
    polygon: [
      [-73.61270141601562, 11.24829959869385],
      [-73.61389923095703, 11.212499618530275],
      [-73.62079620361328, 11.191699981689457],
      [-73.62190246582031, 11.183099746704105],
      [-73.63809967041014, 11.170399665832521],
      [-73.64209747314453, 11.161199569702156],
      [-73.64730072021484, 11.149700164794927],
      [-73.65299987792969, 11.146800041198738],
      [-73.65989685058592, 11.145099639892583],
      [-73.67259979248047, 11.134799957275396],
      [-73.68009948730467, 11.126700401306156],
      [-73.67839813232422, 11.120900154113775],
      [-73.68070220947264, 11.10649967193604],
      [-73.68119812011717, 11.102499961853033],
      [-73.68299865722656, 11.08629989624024],
      [-73.68119812011717, 11.069499969482429],
      [-73.67489624023438, 11.058500289917001],
      [-73.6719970703125, 11.050999641418464],
      [-73.6707992553711, 11.042900085449226],
      [-73.6719970703125, 11.02910041809083],
      [-73.67539978027344, 11.016400337219245],
      [-73.6794967651367, 11.004799842834482],
      [-73.68409729003906, 10.991600036621106],
      [-73.68460083007812, 10.984100341796886],
      [-73.68289947509766, 10.969599723815929],
      [-73.67829895019531, 10.937299728393564],
      [-73.67420196533203, 10.919899940490735],
      [-73.66329956054688, 10.889800071716325],
      [-73.65570068359374, 10.87020015716554],
      [-73.6500015258789, 10.855099678039565],
      [-73.6458969116211, 10.840700149536149],
      [-73.64070129394531, 10.826800346374528],
      [-73.63500213623047, 10.823900222778336],
      [-73.6563034057617, 10.825699806213397],
      [-73.66840362548828, 10.822299957275407],
      [-73.68399810791016, 10.821800231933615],
      [-73.70590209960938, 10.822999954223649],
      [-73.72260284423828, 10.82940006256105],
      [-73.7323989868164, 10.833499908447282],
      [-73.74389648437499, 10.83819961547853],
      [-73.75949859619139, 10.845199584960955],
      [-73.77739715576172, 10.857999801635758],
      [-73.78949737548828, 10.857999801635756],
      [-73.80500030517578, 10.856300354003924],
      [-73.80789947509766, 10.852299690246598],
      [-73.81369781494139, 10.850000381469743],
      [-73.83560180664061, 10.850099563598645],
      [-73.8407974243164, 10.850700378417983],
      [-73.84940338134766, 10.851300239563002],
      [-73.86209869384766, 10.85369968414308],
      [-73.87770080566406, 10.862999916076673],
      [-73.90480041503905, 10.882200241088883],
      [-73.91570281982422, 10.90069961547853],
      [-73.93360137939453, 10.919300079345714],
      [-73.9446029663086, 10.947600364685073],
      [-73.95149993896484, 10.962699890136731],
      [-73.95210266113281, 10.978300094604506],
      [-73.95099639892578, 10.99149990081788],
      [-73.95790100097656, 10.99969959259034],
      [-73.97579956054688, 10.999699592590337],
      [-73.99710083007812, 10.999799728393565],
      [-74.00630187988281, 11.006799697875987],
      [-74.01609802246094, 11.013199806213388],
      [-74.03919982910156, 11.01329994201661],
      [-74.05239868164062, 11.01329994201661],
      [-74.06510162353514, 11.01799964904786],
      [-74.068603515625, 11.027299880981456],
      [-74.06919860839844, 11.033599853515632],
      [-74.0801010131836, 11.037099838256845],
      [-74.0968017578125, 11.041799545288093],
      [-74.11009979248047, 11.0545997619629],
      [-74.12159729003906, 11.069100379943853],
      [-74.12860107421875, 11.0811996459961],
      [-74.14240264892578, 11.084799766540536],
      [-74.1603012084961, 11.08250045776368],
      [-74.17530059814453, 11.086600303649911],
      [-74.18509674072266, 11.100000381469735],
      [-74.19830322265625, 11.097700119018562],
      [-74.2074966430664, 11.089099884033208],
      [-74.25019836425781, 11.093899726867683],
      [-74.25019836425781, 11.10490036010743],
      [-74.2479019165039, 11.11349964141846],
      [-74.25140380859375, 11.128000259399416],
      [-74.25540161132812, 11.135499954223636],
      [-74.26000213623045, 11.15279960632325],
      [-74.2605972290039, 11.173600196838382],
      [-74.2698974609375, 11.209500312805176],
      [-74.2531967163086, 11.22910022735596],
      [-74.2376022338867, 11.241100311279295],
      [-74.23590087890625, 11.25090026855469],
      [-74.22669982910156, 11.258399963378908],
      [-74.21569824218749, 11.26469993591309],
      [-74.22319793701172, 11.279800415039064],
      [-74.21510314941406, 11.291299819946289],
      [-74.20539855957031, 11.30340003967285],
      [-74.19730377197266, 11.299300193786618],
      [-74.18810272216797, 11.296899795532228],
      [-74.18060302734375, 11.298100471496584],
      [-74.17649841308594, 11.305600166320799],
      [-74.17250061035156, 11.318799972534176],
      [-74.16790008544922, 11.318799972534178],
      [-74.16100311279295, 11.317000389099121],
      [-74.15640258789062, 11.312999725341795],
      [-74.14939880371094, 11.317600250244139],
      [-74.14140319824219, 11.324500083923338],
      [-74.13680267333984, 11.323300361633299],
      [-74.13159942626953, 11.325599670410154],
      [-74.13330078125, 11.339500427246092],
      [-74.12750244140625, 11.326100349426268],
      [-74.11139678955078, 11.320899963378906],
      [-74.10559844970703, 11.327199935913084],
      [-74.10279846191405, 11.340499877929684],
      [-74.091796875, 11.327699661254881],
      [-74.08260345458984, 11.324199676513672],
      [-74.06759643554688, 11.330499649047852],
      [-74.05840301513672, 11.332799911499022],
      [-74.03990173339844, 11.331000328063963],
      [-74.03189849853516, 11.330900192260739],
      [-74.01860046386719, 11.324500083923338],
      [-74.010498046875, 11.322799682617188],
      [-73.99610137939453, 11.311200141906735],
      [-73.97769927978514, 11.305899620056154],
      [-73.9552001953125, 11.299400329589842],
      [-73.93329620361328, 11.286600112915037],
      [-73.90850067138672, 11.276100158691408],
      [-73.89230346679688, 11.270899772644045],
      [-73.87039947509766, 11.260399818420412],
      [-73.86000061035156, 11.25339984893799],
      [-73.83580017089844, 11.25329971313477],
      [-73.80699920654297, 11.251399993896488],
      [-73.779296875, 11.250200271606449],
      [-73.76029968261719, 11.249500274658205],
      [-73.73090362548828, 11.24709987640381],
      [-73.71820068359375, 11.248200416564945],
      [-73.69110107421875, 11.250399589538574],
      [-73.66000366210936, 11.24969959259033],
      [-73.63870239257811, 11.247300148010252],
      [-73.61270141601562, 11.24829959869385],
    ],
    id: "11",
  },
  {
    name: "Aracataca",
    location: {
      longitude: "-73.8328467",
      latitude: "10.654623",
    },
    image:'aracataca.jpg',
    url: 'https://www.siturmagdalena.com/destinos/ver/3',
    population: "41.872 habitantes",
    sites: [{ name: ["Casa Museo Gabriel García Márquez", "Casa del Telegrafista", "Iglesia San José", "Río Aracataca", " The Gypsy REsidence Tours", "Teyuna Tours"] }],
    polygon: [
      [-73.63500213623047, 10.823900222778336],
      [-73.59001159667969, 10.843699455261246],
      [-73.64189910888672, 10.801899909973162],
      [-73.66320037841797, 10.793899536132832],
      [-73.6729965209961, 10.788200378417987],
      [-73.68859863281249, 10.779600143432633],
      [-73.69090270996092, 10.77149963378908],
      [-73.68509674072266, 10.760000228881857],
      [-73.66950225830078, 10.758700370788592],
      [-73.64129638671875, 10.762700080871602],
      [-73.62570190429688, 10.763199806213395],
      [-73.6095962524414, 10.757900238037127],
      [-73.60260009765625, 10.747500419616717],
      [-73.60209655761719, 10.731300354003922],
      [-73.61180114746094, 10.712300300598164],
      [-73.62570190429688, 10.693900108337424],
      [-73.63227844238281, 10.687244415283224],
      [-73.6343002319336, 10.685199737548848],
      [-73.6406021118164, 10.670800209045433],
      [-73.64350128173828, 10.655799865722678],
      [-73.6428985595703, 10.64249992370608],
      [-73.6428985595703, 10.637299537658713],
      [-73.67350006103516, 10.629400253295923],
      [-73.68669891357422, 10.629400253295922],
      [-73.7029037475586, 10.618499755859398],
      [-73.7040023803711, 10.600000381469751],
      [-73.70169830322266, 10.576899528503441],
      [-73.70690155029297, 10.560799598693873],
      [-73.7229995727539, 10.557399749755882],
      [-73.72820281982422, 10.545800209045431],
      [-73.74320220947266, 10.537799835205101],
      [-73.75299835205077, 10.542499542236353],
      [-73.76679992675781, 10.533900260925318],
      [-73.77950286865234, 10.521800041198754],
      [-73.79910278320312, 10.514399528503441],
      [-73.8095016479492, 10.510399818420437],
      [-73.82849884033203, 10.502900123596222],
      [-73.8572998046875, 10.49380016326907],
      [-73.8947982788086, 10.486499786376983],
      [-73.92130279541016, 10.486000061035181],
      [-73.93569946289061, 10.480299949646025],
      [-73.94550323486328, 10.470499992370632],
      [-73.95580291748047, 10.457300186157253],
      [-73.97260284423828, 10.454400062561062],
      [-73.98750305175781, 10.452199935913114],
      [-73.99160003662108, 10.445899963378935],
      [-73.99909973144531, 10.435500144958523],
      [-74.01519775390625, 10.420000076293976],
      [-74.02670288085938, 10.41189956665042],
      [-74.04340362548828, 10.409700393676786],
      [-74.06792449951172, 10.415534019470243],
      [-74.06819915771484, 10.415599822998075],
      [-74.0780029296875, 10.421400070190456],
      [-74.09239959716797, 10.432399749755886],
      [-74.10569763183594, 10.45149993896487],
      [-74.12190246582031, 10.456800460815456],
      [-74.12760162353516, 10.464300155639675],
      [-74.1397018432617, 10.46730041503909],
      [-74.14720153808594, 10.473099708557156],
      [-74.15760040283203, 10.48470020294192],
      [-74.17030334472656, 10.497400283813501],
      [-74.18009948730469, 10.504400253295923],
      [-74.21179962158203, 10.517299652099634],
      [-74.22570037841797, 10.5289001464844],
      [-74.23370361328125, 10.529999732971216],
      [-74.23660278320312, 10.534099578857445],
      [-74.24700164794922, 10.545700073242209],
      [-74.27410125732422, 10.579299926757837],
      [-74.29412841796874, 10.596053123474144],
      [-74.2881088256836, 10.607328414917015],
      [-74.2868423461914, 10.637791633605975],
      [-74.2881088256836, 10.653023719787617],
      [-74.2836685180664, 10.674115180969258],
      [-74.27700042724608, 10.657899856567402],
      [-74.26090240478516, 10.640500068664574],
      [-74.2499008178711, 10.635199546813988],
      [-74.23320007324219, 10.63860034942629],
      [-74.22979736328125, 10.64319992065432],
      [-74.22339630126953, 10.641500473022484],
      [-74.20960235595703, 10.637399673461935],
      [-74.1957015991211, 10.628700256347678],
      [-74.17729949951172, 10.617600440979027],
      [-74.15190124511717, 10.607099533081078],
      [-74.11160278320312, 10.601200103759792],
      [-74.09950256347656, 10.60690021514895],
      [-74.09660339355467, 10.612700462341328],
      [-74.08619689941406, 10.637999534606953],
      [-74.07469940185545, 10.643799781799338],
      [-74.07379913330078, 10.644623756408713],
      [-74.05909729003906, 10.658100128173848],
      [-74.03839874267577, 10.665599822998066],
      [-74.01069641113281, 10.67759990692141],
      [-73.98419952392577, 10.692999839782738],
      [-73.95999908447266, 10.711400032043477],
      [-73.9386978149414, 10.722299575805684],
      [-73.9104995727539, 10.733200073242205],
      [-73.89550018310547, 10.746399879455584],
      [-73.87930297851561, 10.760800361633317],
      [-73.86260223388672, 10.775699615478533],
      [-73.84190368652344, 10.793000221252457],
      [-73.81939697265625, 10.818900108337417],
      [-73.81369781494139, 10.850000381469743],
      [-73.80789947509766, 10.852299690246598],
      [-73.80500030517578, 10.856300354003924],
      [-73.78949737548828, 10.857999801635756],
      [-73.77739715576172, 10.857999801635758],
      [-73.75949859619139, 10.845199584960955],
      [-73.74389648437499, 10.83819961547853],
      [-73.7323989868164, 10.833499908447282],
      [-73.72260284423828, 10.82940006256105],
      [-73.70590209960938, 10.822999954223649],
      [-73.68399810791016, 10.821800231933615],
      [-73.66840362548828, 10.822299957275407],
      [-73.6563034057617, 10.825699806213397],
      [-73.63500213623047, 10.823900222778336],
    ],
    id: "12",
  },
  {
    name: "El Banco",
    location: {
      longitude: "-73.9779857",
      latitude: "9.1235494",
    },
    image:'elbanco.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/4",
    population: "53.544 habitantes",
    sites: [{ name: ["Mirador Turístico Ciénaga De Zapatosa", "Plaza de mercado", "Mompox Retro Travel", "Iglesia de la Inmaculada concepción", "Mercado municipal", "Plaza de Santo Domingo"] }],
    polygon: [
      [-73.99839782714844, 9.333399772644093],
      [-73.9845962524414, 9.3171997070313],
      [-73.97480010986328, 9.288800239563042],
      [-73.96839904785156, 9.275500297546438],
      [-73.96440124511719, 9.25759983062749],
      [-73.96959686279297, 9.234499931335499],
      [-73.97239685058594, 9.223500251770073],
      [-73.9844970703125, 9.206800460815481],
      [-73.98570251464844, 9.193499565124565],
      [-73.9833984375, 9.192399978637747],
      [-73.96199798583984, 9.187700271606499],
      [-73.93840026855469, 9.188699722290094],
      [-73.91760253906249, 9.185199737548881],
      [-73.90840148925781, 9.179900169372608],
      [-73.90440368652344, 9.16320037841802],
      [-73.90029907226562, 9.148699760437065],
      [-73.8980026245117, 9.137100219726614],
      [-73.8864974975586, 9.110500335693411],
      [-73.85299682617188, 9.080900192260797],
      [-73.84380340576172, 9.067000389099174],
      [-73.83689880371094, 9.05889987945562],
      [-73.83280181884766, 9.04790019989019],
      [-73.82879638671875, 9.040399551391657],
      [-73.82879638671875, 9.031700134277399],
      [-73.83399963378906, 9.014399528503471],
      [-73.85639953613281, 8.997799873352106],
      [-73.86049652099608, 8.993200302124077],
      [-73.8656005859375, 8.983900070190485],
      [-73.87079620361328, 8.97239971160894],
      [-73.8749008178711, 8.96319961547857],
      [-73.8749008178711, 8.959699630737362],
      [-73.877197265625, 8.956800460815487],
      [-73.90889739990234, 8.970299720764213],
      [-73.93080139160156, 8.96979999542242],
      [-73.95149993896484, 8.969300270080621],
      [-73.97460174560545, 8.978599548339899],
      [-73.9854965209961, 8.978099822998102],
      [-74.00279998779297, 8.975299835205133],
      [-74.01208496093749, 8.97749996185308],
      [-74.02010345458984, 8.97939968109136],
      [-74.01840209960938, 8.989199638366754],
      [-74.02420043945312, 8.996199607849176],
      [-74.04440307617186, 9.00259971618658],
      [-74.0530014038086, 9.018799781799371],
      [-74.0697021484375, 9.020600318908746],
      [-74.08300018310547, 9.00329971313482],
      [-74.09510040283203, 9.001700401306206],
      [-74.11640167236328, 9.00689983367925],
      [-74.12850189208984, 9.005299568176323],
      [-74.14240264892578, 9.007100105285698],
      [-74.14469909667969, 9.008799552917536],
      [-74.14700317382812, 9.008199691772514],
      [-74.15039825439453, 9.009400367736871],
      [-74.14929962158203, 9.014599800109918],
      [-74.14929962158203, 9.023799896240288],
      [-74.14759826660155, 9.032500267028865],
      [-74.16539764404297, 9.043000221252496],
      [-74.18499755859375, 9.051699638366753],
      [-74.19599914550781, 9.05980014801031],
      [-74.19950103759766, 9.067899703979547],
      [-74.20349884033202, 9.07369995117193],
      [-74.15969848632812, 9.086799621582086],
      [-74.14929962158203, 9.089699745178276],
      [-74.12049865722656, 9.104599952697805],
      [-74.10780334472656, 9.12010002136236],
      [-74.10150146484375, 9.15359973907476],
      [-74.10150146484375, 9.178999900817924],
      [-74.08999633789061, 9.198599815368706],
      [-74.07559967041016, 9.210100173950247],
      [-74.06929779052733, 9.222200393676811],
      [-74.06809997558594, 9.236599922180227],
      [-74.05660247802733, 9.268400192260794],
      [-74.04399871826172, 9.297800064086966],
      [-74.02549743652344, 9.313300132751515],
      [-74.00650024414062, 9.326499938964892],
      [-73.99839782714844, 9.333399772644093],
    ],
    id: "13",
  },
  {
    name: "Guamal",
    location: {
      longitude: "-74.1566607",
      latitude: "9.244195",
    },
    image:'guamal.jpg',
    url: "https://www.siturmagdalena.com/destinos/ver/17",
    population: "25.058 habitantes",
    sites: [{ name: ["Iglesia Santa Maria Magdalena", "Centro comercial Carnaval", "Teatro Rex", "Plaza San Nicolás", "Parque Washington", "Antiguo edificio de la Aduana"] }],
    polygon: [
      [-74.04109954833984, 9.378000259399462],
      [-74.03420257568358, 9.372799873352102],
      [-74.02839660644531, 9.368200302124073],
      [-74.01799774169922, 9.358900070190481],
      [-74.0105972290039, 9.353699684143114],
      [-73.99960327148438, 9.34440040588384],
      [-73.99839782714844, 9.333399772644093],
      [-74.00650024414062, 9.326499938964892],
      [-74.02549743652344, 9.313300132751515],
      [-74.04399871826172, 9.297800064086966],
      [-74.05660247802733, 9.268400192260794],
      [-74.06809997558594, 9.236599922180227],
      [-74.06929779052733, 9.222200393676811],
      [-74.07559967041016, 9.210100173950247],
      [-74.08999633789061, 9.198599815368706],
      [-74.10150146484375, 9.178999900817924],
      [-74.10150146484375, 9.15359973907476],
      [-74.10780334472656, 9.12010002136236],
      [-74.12049865722656, 9.104599952697805],
      [-74.14929962158203, 9.089699745178276],
      [-74.15969848632812, 9.086799621582086],
      [-74.20349884033202, 9.07369995117193],
      [-74.21269989013672, 9.087599754333553],
      [-74.25430297851562, 9.128199577331598],
      [-74.26349639892578, 9.139800071716362],
      [-74.27960205078125, 9.148599624633842],
      [-74.3021011352539, 9.151000022888235],
      [-74.31939697265625, 9.147600173950245],
      [-74.3228988647461, 9.14700031280523],
      [-74.32689666748047, 9.154000282287651],
      [-74.31710052490234, 9.159700393676811],
      [-74.31079864501953, 9.163700103759819],
      [-74.30899810791016, 9.173000335693413],
      [-74.31189727783203, 9.181599617004446],
      [-74.32119750976561, 9.18799972534185],
      [-74.28720092773438, 9.18959999084478],
      [-74.2698974609375, 9.220199584960989],
      [-74.26300048828125, 9.234600067138725],
      [-74.25379943847656, 9.251299858093315],
      [-74.23529815673828, 9.26329994201665],
      [-74.22260284423828, 9.266200065612844],
      [-74.21920013427734, 9.267900466919],
      [-74.21630096435545, 9.278300285339407],
      [-74.22029876708984, 9.295599937439016],
      [-74.21749877929688, 9.306599617004444],
      [-74.19730377197266, 9.313400268554739],
      [-74.18060302734375, 9.321999549865776],
      [-74.15640258789062, 9.325400352478075],
      [-74.13500213623047, 9.327599525451712],
      [-74.11720275878906, 9.32979965209966],
      [-74.09590148925781, 9.347100257873588],
      [-74.0802993774414, 9.360300064086962],
      [-74.0624008178711, 9.369500160217335],
      [-74.04509735107422, 9.36999988555913],
      [-74.04109954833984, 9.378000259399462],
    ],
    id: "14",
  },
];

export const magdalenaPolygon = [
  [-74.09179687546849, 11.327699661651142],
  [-74.08260345404383, 11.324199676824101],
  [-74.06759643516982, 11.330499649296339],
  [-74.05840301519586, 11.332799911900713],
  [-74.03990173386512, 11.331000327726594],
  [-74.0318984985445, 11.330900192292427],
  [-74.0186004638614, 11.324500084517256],
  [-74.01049804632032, 11.322799683200522],
  [-73.99610137945845, 11.311200141426633],
  [-73.9776992803728, 11.305899619887063],
  [-73.9552001958948, 11.299400330133256],
  [-73.93329620347332, 11.286600113058206],
  [-73.90850067164271, 11.27610015853333],
  [-73.89230346689855, 11.270899772495122],
  [-73.87039947514593, 11.26039981851384],
  [-73.86000061018336, 11.253399848424028],
  [-73.83580017147956, 11.253299713673195],
  [-73.80699920713292, 11.251399993708086],
  [-73.77929687533744, 11.250200271295757],
  [-73.7602996823881, 11.249500274233103],
  [-73.73090362493697, 11.247099876006786],
  [-73.71820068300282, 11.248200417151832],
  [-73.69110107377819, 11.2503995901252],
  [-73.66000366257116, 11.249699592994572],
  [-73.63870239207552, 11.247300148115091],
  [-73.61270141602198, 11.248299598722731],
  [-73.61389923042486, 11.212499618741793],
  [-73.62079620335128, 11.191699981444174],
  [-73.62190246565434, 11.183099746573614],
  [-73.63809966983048, 11.170399666116683],
  [-73.64209747365351, 11.161199569724142],
  [-73.64730072031958, 11.14970016427531],
  [-73.6529998781285, 11.146800040976528],
  [-73.65989685096352, 11.145099640384425],
  [-73.6725997927324, 11.134799957169447],
  [-73.68009948715584, 11.126700400986893],
  [-73.67839813254551, 11.12090015435495],
  [-73.68070220938102, 11.1064996715965],
  [-73.68119812058377, 11.102499961883941],
  [-73.68299865701145, 11.08629989641523],
  [-73.6811981197338, 11.069499969979873],
  [-73.67489624020612, 11.058500289642888],
  [-73.67199707070947, 11.050999641751677],
  [-73.67079925577858, 11.04290008603121],
  [-73.67199707063645, 11.029100418313618],
  [-73.67539978081436, 11.01640033737667],
  [-73.67949676535785, 11.004799842352782],
  [-73.68409729028843, 10.991600036436306],
  [-73.68460082960695, 10.984100342374607],
  [-73.68289947461992, 10.969599724009669],
  [-73.67829895078286, 10.937299728058298],
  [-73.6742019649164, 10.919899940710831],
  [-73.66329956024587, 10.889800071437326],
  [-73.65570068400686, 10.87020015720908],
  [-73.65000152622967, 10.855099677915907],
  [-73.64589691212733, 10.84070015002096],
  [-73.64070129373438, 10.826800346824964],
  [-73.63500213629689, 10.823900222858741],
  [-73.59001159701066, 10.843699455369682],
  [-73.64189910878592, 10.801899910278646],
  [-73.6632003784514, 10.79389953553716],
  [-73.67299652053585, 10.788200378003463],
  [-73.68859863318818, 10.779600143742936],
  [-73.69090270985224, 10.771499634210912],
  [-73.68509674089609, 10.760000228628677],
  [-73.66950225785547, 10.758700370385816],
  [-73.64129638616826, 10.762700080778504],
  [-73.62570190478655, 10.763199805807298],
  [-73.6095962522522, 10.757900237759275],
  [-73.60260009747113, 10.747500419808315],
  [-73.60209655808555, 10.731300354470768],
  [-73.6118011472927, 10.712300300224253],
  [-73.6257019047281, 10.693900107815764],
  [-73.63227844199014, 10.687244415658279],
  [-73.63430023193077, 10.685199738069834],
  [-73.64060211174831, 10.670800209505268],
  [-73.64350128132483, 10.65579986622926],
  [-73.64289855930674, 10.642499923227229],
  [-73.64289855986178, 10.637299538182601],
  [-73.64289855934899, 10.62979984341346],
  [-73.64119720482833, 10.615400313828443],
  [-73.63770294157955, 10.596899986723079],
  [-73.63369751017483, 10.581800460566114],
  [-73.6296005249213, 10.56560039523995],
  [-73.62729644737628, 10.545399666279415],
  [-73.62729644801277, 10.528100013430333],
  [-73.6267013543855, 10.514800072096707],
  [-73.64340210005625, 10.505000114003824],
  [-73.65670013392919, 10.503899574616565],
  [-73.6664962770446, 10.489600182134348],
  [-73.67220306401188, 10.47399997669872],
  [-73.68260192825352, 10.458999633452073],
  [-73.69409942674156, 10.449199675971071],
  [-73.70800018296063, 10.446399689028528],
  [-73.72810363717383, 10.443599700652735],
  [-73.73509979235243, 10.443599700501156],
  [-73.74369812026175, 10.43439960431674],
  [-73.74250030498094, 10.418199539296385],
  [-73.74140167196332, 10.412500382000998],
  [-73.74710082949348, 10.4078998561974],
  [-73.75460052499473, 10.408499717403783],
  [-73.76619720477994, 10.411999702731162],
  [-73.77480316132475, 10.398699759920497],
  [-73.78630065902028, 10.393600463639725],
  [-73.79609680215495, 10.39420032544563],
  [-73.80300140429813, 10.391900062060571],
  [-73.81400299026136, 10.38389968886492],
  [-73.83760070835764, 10.38109970122267],
  [-73.85379791240025, 10.380599975581966],
  [-73.86990356459268, 10.381199836173852],
  [-73.88839721688498, 10.383000373352857],
  [-73.90679931673164, 10.379099845900539],
  [-73.93450164789859, 10.360099792034443],
  [-73.9580993652411, 10.338899612957565],
  [-73.96959686219219, 10.319299697745935],
  [-73.97640228266036, 10.311902999481234],
  [-73.9833984370208, 10.304300308290795],
  [-73.9875030511732, 10.295700073513666],
  [-73.99210357661677, 10.28180026998681],
  [-73.99720001213706, 10.267399787990088],
  [-74.00879669183728, 10.248999595220955],
  [-74.02030181842176, 10.239199637787882],
  [-74.02950286808078, 10.238100052130228],
  [-74.03810119647069, 10.215000152500773],
  [-74.04560089162104, 10.202899932309569],
  [-74.06349945045315, 10.201800345958159],
  [-74.07160186815399, 10.187399864708489],
  [-74.0698013305755, 10.172400474987667],
  [-74.07730102555848, 10.15970039425232],
  [-74.08480072052458, 10.153400421253949],
  [-74.09400177020648, 10.136699676378843],
  [-74.10260009781221, 10.10550022144356],
  [-74.10549926705156, 10.087599753792462],
  [-74.1083984378153, 10.076100349729453],
  [-74.11064910838886, 10.066512107342305],
  [-74.1107025145052, 10.066300391900214],
  [-74.1037979120247, 10.05410003695397],
  [-74.09680175753897, 10.036199570270325],
  [-74.08699798540151, 10.017700195573164],
  [-74.0840988164689, 10.006699562025402],
  [-74.07430267303742, 9.9951000215181],
  [-74.0662994389248, 9.987500191158892],
  [-74.06449890086493, 9.985799789700044],
  [-74.0518035894538, 9.97360038703179],
  [-74.03160095216329, 9.957400321969583],
  [-74.03160095235101, 9.949299812130603],
  [-74.02300262477274, 9.931900023825179],
  [-73.99990081740275, 9.916799545380869],
  [-73.98670196588631, 9.911499977282585],
  [-73.98099517835139, 9.906004905274878],
  [-73.95960235633162, 9.88539981858484],
  [-73.95149993870733, 9.864000320694009],
  [-73.93650054879379, 9.842000008033777],
  [-73.92549896268736, 9.823499679857678],
  [-73.91169738716886, 9.811300277427708],
  [-73.88569641079829, 9.796199799142915],
  [-73.88169860793928, 9.791500091488624],
  [-73.87989807166605, 9.789799689881656],
  [-73.87650299072165, 9.78400039713032],
  [-73.87419891368653, 9.781700133744822],
  [-73.86260223393052, 9.773500442824526],
  [-73.86150360164372, 9.76309966992352],
  [-73.86489868124146, 9.748100280425161],
  [-73.8664474484262, 9.737265586741186],
  [-73.86720275822809, 9.732000351524627],
  [-73.86090087891542, 9.719200133869034],
  [-73.85859680125881, 9.713999748807437],
  [-73.84700012236107, 9.699500084335103],
  [-73.83899688706197, 9.683899879825873],
  [-73.83090210005676, 9.668299674552575],
  [-73.82802581745646, 9.662332534944392],
  [-73.8227996828089, 9.651499748116784],
  [-73.81759643594623, 9.633000373979236],
  [-73.81240081795077, 9.617400168872905],
  [-73.81349945045952, 9.602399826332661],
  [-73.81009674093436, 9.589599609907218],
  [-73.80950164799481, 9.582099914986319],
  [-73.81349945088066, 9.577500343648701],
  [-73.81580352777993, 9.574099540928602],
  [-73.8227996826684, 9.572400093018691],
  [-73.8504028320458, 9.574199675918944],
  [-73.86080169663153, 9.580599784559862],
  [-73.89420318571533, 9.580200195078616],
  [-73.93569946232076, 9.576299667548788],
  [-73.95819854699572, 9.575200081283203],
  [-73.97380065891456, 9.57530021664817],
  [-73.99400329549869, 9.576000214099919],
  [-74.01699829062248, 9.582400322518502],
  [-74.05329895036101, 9.581999778549651],
  [-74.07579803477489, 9.561300278284174],
  [-74.08619689979183, 9.545700073121887],
  [-74.10810089122941, 9.522700309184216],
  [-74.119598388672, 9.507800101772437],
  [-74.1277008052877, 9.500300407369298],
  [-74.13690185589088, 9.495699882794622],
  [-74.14779663033913, 9.487700462445238],
  [-74.154701232432, 9.483599662869823],
  [-74.15632629340489, 9.47710418757355],
  [-74.14839935255429, 9.466899871610991],
  [-74.10169982868865, 9.444100380202999],
  [-74.08609771691852, 9.43190002413861],
  [-74.07520294240447, 9.418600082061028],
  [-74.06250000004836, 9.405300140299435],
  [-74.04630279529029, 9.387299538010895],
  [-74.04109954862183, 9.378000259322564],
  [-74.03420257513547, 9.37279987334046],
  [-74.02839660654939, 9.36820030221064],
  [-74.01799774206927, 9.358900069604013],
  [-74.01059722909572, 9.353699683798231],
  [-73.99960327098805, 9.344400406179197],
  [-73.99839782679996, 9.3333997720842],
  [-73.9845962520467, 9.317199707377625],
  [-73.9748001103934, 9.288800239305633],
  [-73.96839904750495, 9.275500297510971],
  [-73.96440124513369, 9.257599831181938],
  [-73.96959686294527, 9.23449993093062],
  [-73.97239685048072, 9.223500252243186],
  [-73.98449707005425, 9.206800460258288],
  [-73.98570251453151, 9.193499564970427],
  [-73.98339843753482, 9.192399978521317],
  [-73.96199798533122, 9.187700271365888],
  [-73.93840026820804, 9.188699722607408],
  [-73.91760253908687, 9.185199736992097],
  [-73.90840148944437, 9.179900169336193],
  [-73.90440368700487, 9.163200377971744],
  [-73.9002990725759, 9.148699760984274],
  [-73.89800262396857, 9.137100219160397],
  [-73.8864974978881, 9.110500335655004],
  [-73.85299682594558, 9.080900192564426],
  [-73.84380340558512, 9.067000389448893],
  [-73.83689880352546, 9.058899878864233],
  [-73.83280181848473, 9.047900199477377],
  [-73.8287963863169, 9.040399551948276],
  [-73.82879638661458, 9.031700133797427],
  [-73.83399963402213, 9.01439952844161],
  [-73.85639953624957, 8.997799873422359],
  [-73.86049652153193, 8.993200302461956],
  [-73.86560058594297, 8.983900070751035],
  [-73.87079620328907, 8.972399711422648],
  [-73.87490081759456, 8.963199614972574],
  [-73.87490081845068, 8.959699630717225],
  [-73.87719726568024, 8.956800460815513],
  [-73.90889739989663, 8.970299721294506],
  [-73.93080139188851, 8.969799995739443],
  [-73.95149993921031, 8.969300269659296],
  [-73.97460174537139, 8.978599548608086],
  [-73.98549652099737, 8.9780998231862],
  [-74.00279998730788, 8.975299835520634],
  [-74.01208496075593, 8.97749996244346],
  [-74.020103454266, 8.979399681310884],
  [-74.01840209967138, 8.989199637983795],
  [-74.02420043893014, 8.996199607667856],
  [-74.04440307656532, 9.002599716671142],
  [-74.05300140426294, 9.018799781865294],
  [-74.06970214901231, 9.020600319041657],
  [-74.08300018296023, 9.003299713501368],
  [-74.09510040322512, 9.001700401696555],
  [-74.11640167239871, 9.006899833348573],
  [-74.1285018924569, 9.005299568540197],
  [-74.14240264912166, 9.007100104730924],
  [-74.14469909674904, 9.008799552706575],
  [-74.14700317334623, 9.008199691284378],
  [-74.15039825459155, 9.00940036791475],
  [-74.14929962206551, 9.014599800329428],
  [-74.14929962110902, 9.02379989587452],
  [-74.1475982666613, 9.032500266814969],
  [-74.16539764422657, 9.04300022111122],
  [-74.18499755845315, 9.051699638874387],
  [-74.19599914527443, 9.05980014771135],
  [-74.19950103752613, 9.067899704552378],
  [-74.20349884063849, 9.073699951088544],
  [-74.21269988977625, 9.087599754052716],
  [-74.25430297799647, 9.128199577513355],
  [-74.26349639893165, 9.139800071999009],
  [-74.27960205064886, 9.148599624080482],
  [-74.30210113584042, 9.15100002236531],
  [-74.3193969723263, 9.147600174507335],
  [-74.32289886464659, 9.147000312542298],
  [-74.32689666711832, 9.154000282717659],
  [-74.31710052498987, 9.15970039386542],
  [-74.31079864470597, 9.163700104044189],
  [-74.30899810784216, 9.173000335662763],
  [-74.31189727754575, 9.1815996173203],
  [-74.32119751003378, 9.18799972532752],
  [-74.32520294241304, 9.190299987286052],
  [-74.34480285671748, 9.198499680063911],
  [-74.3545989995865, 9.20429992655093],
  [-74.36380004869571, 9.208999633598289],
  [-74.38050079288507, 9.20619964632356],
  [-74.39209747338514, 9.202799797598246],
  [-74.40820312557068, 9.198800086379283],
  [-74.41220092718237, 9.196499824520014],
  [-74.42489624001593, 9.191900253407253],
  [-74.43240356478633, 9.197699547365339],
  [-74.43190002470958, 9.205800055968025],
  [-74.43190002420296, 9.212699889979064],
  [-74.44509887636502, 9.227199554488553],
  [-74.45149993851751, 9.23540019997232],
  [-74.4607009882918, 9.24349975609105],
  [-74.48320007311219, 9.238400459389158],
  [-74.49530029355483, 9.23439979552535],
  [-74.50679779007991, 9.22630023962045],
  [-74.51599883981719, 9.22229957595682],
  [-74.53389739963161, 9.223600388019133],
  [-74.53620147759408, 9.230500221015076],
  [-74.54080200135664, 9.245499611290153],
  [-74.55930328414027, 9.265800475618665],
  [-74.57369995094724, 9.282099723541764],
  [-74.60140228243068, 9.303600311511419],
  [-74.62390136761412, 9.317500114014889],
  [-74.63369750928504, 9.333200454801972],
  [-74.63770294136098, 9.351699829132082],
  [-74.63999938963627, 9.36209964783714],
  [-74.64980316207063, 9.370800018560036],
  [-74.6637725833706, 9.375182152134968],
  [-74.6682968135618, 9.376600265709593],
  [-74.67980194102213, 9.397500038194135],
  [-74.68679809613036, 9.402700423879322],
  [-74.75230407735144, 9.410614966862981],
  [-74.76570129337614, 9.409999847819622],
  [-74.77210235640722, 9.416299819739416],
  [-74.78250122041133, 9.42389965048846],
  [-74.79460144043131, 9.428000450183108],
  [-74.80899810804425, 9.428600310749653],
  [-74.8118972776406, 9.43379974365828],
  [-74.8142013550263, 9.441399574511605],
  [-74.81590270996601, 9.450599670101687],
  [-74.81590270944507, 9.463899612546308],
  [-74.81300353966068, 9.475399971102318],
  [-74.81020355247743, 9.493900298765254],
  [-74.80960082965943, 9.507200240600833],
  [-74.8085021973086, 9.514100074581355],
  [-74.80960082951111, 9.51640033774192],
  [-74.806701659827, 9.527400017301028],
  [-74.81079864516182, 9.541799545877414],
  [-74.80850219708948, 9.55220031734619],
  [-74.79699707007846, 9.559700011921715],
  [-74.78949737496923, 9.569499969113648],
  [-74.78430175731654, 9.583900451942192],
  [-74.78140258763955, 9.585599899007144],
  [-74.77739715575774, 9.600600242975725],
  [-74.78780364934285, 9.61159992181365],
  [-74.796401976967, 9.6215000152496],
  [-74.81079864517908, 9.637700080459325],
  [-74.81890106173454, 9.652199745371764],
  [-74.82640075638214, 9.6603002549973],
  [-74.83219909639547, 9.66609954863991],
  [-74.84079742451308, 9.679400443743447],
  [-74.8466033939695, 9.690999984646778],
  [-74.84549713076782, 9.705499649152717],
  [-74.84079742417394, 9.71240043629777],
  [-74.8315963746322, 9.726200103676723],
  [-74.82469940212428, 9.741800308317462],
  [-74.82420349122576, 9.752200126961602],
  [-74.82299804690136, 9.761400223156699],
  [-74.82879638688897, 9.77239990232482],
  [-74.83339691103562, 9.781700134099236],
  [-74.8396987910126, 9.788000106246866],
  [-74.84380340624391, 9.798999786848377],
  [-74.84380340621385, 9.804800033147686],
  [-74.85299682627436, 9.81229972799959],
  [-74.85240173373795, 9.815799713326319],
  [-74.8529968265597, 9.826199531544155],
  [-74.85710144092141, 9.840100288195138],
  [-74.86689758350295, 9.851099967556241],
  [-74.87719726608523, 9.859199523697045],
  [-74.88189697247462, 9.873700141818649],
  [-74.88480377254808, 9.88469982120135],
  [-74.88999938909014, 9.897399902933856],
  [-74.89170074512963, 9.906700133784373],
  [-74.88880157437616, 9.912400245571687],
  [-74.8853988641967, 9.922200203222031],
  [-74.87729644721271, 9.930899619693065],
  [-74.87329864517855, 9.934300422233825],
  [-74.86521911594751, 9.94842052424137],
  [-74.85643005407776, 9.95737171193279],
  [-74.83937835642213, 9.962269783366311],
  [-74.82873535118172, 9.969870567916104],
  [-74.81522369333157, 9.977639198797233],
  [-74.7963027949642, 9.998076438814643],
  [-74.7963027951868, 10.023462295248827],
  [-74.79630279488632, 10.023748397446454],
  [-74.80542755169996, 10.029321671085482],
  [-74.81201171882208, 10.03573989870235],
  [-74.81690979058112, 10.04080677004172],
  [-74.82265472454228, 10.052123070063029],
  [-74.82802581809136, 10.06060981797165],
  [-74.84041595468419, 10.082250595414811],
  [-74.8612136843832, 10.099051475671764],
  [-74.87441253684852, 10.105450630416367],
  [-74.8952178952302, 10.107851028848605],
  [-74.9200134279499, 10.105051040630759],
  [-74.93551635689694, 10.110350608674393],
  [-74.94821166947162, 10.119650841002068],
  [-74.95001220699025, 10.130651473729024],
  [-74.95035552945605, 10.143113136755085],
  [-74.94259643528076, 10.159299849874392],
  [-74.94080352782372, 10.17430019336519],
  [-74.93969726608165, 10.186400413110322],
  [-74.93509674019273, 10.20320034023891],
  [-74.92759704578448, 10.214099884078946],
  [-74.91815948528065, 10.243124961603245],
  [-74.91271972715941, 10.256701469593278],
  [-74.89540100073113, 10.31270027119468],
  [-74.88670349109857, 10.329500197921824],
  [-74.8815994257488, 10.340999603823992],
  [-74.87640380903987, 10.343299865167252],
  [-74.87059783934298, 10.36120033216021],
  [-74.86061859169094, 10.375731468585862],
  [-74.85679626454898, 10.381299972887438],
  [-74.85099792459914, 10.393400191810185],
  [-74.84069824185457, 10.405500411592158],
  [-74.8384017941231, 10.415300369401514],
  [-74.83319854759618, 10.425700187776885],
  [-74.82920074413153, 10.441300392485392],
  [-74.82920074444938, 10.44880008703807],
  [-74.82898712126246, 10.449341773702775],
  [-74.82740020781824, 10.453399658711913],
  [-74.81880187994278, 10.463800429790105],
  [-74.81300354037191, 10.476400375000825],
  [-74.79579925507839, 10.500599861049663],
  [-74.77439880425861, 10.521900177425762],
  [-74.76059722949903, 10.535699843794273],
  [-74.74680328318473, 10.548999786653745],
  [-74.74330139138642, 10.562800407435603],
  [-74.7427978519802, 10.573200225470554],
  [-74.7427978521107, 10.579000473305026],
  [-74.74099731445448, 10.585300445264645],
  [-74.74449920632308, 10.59169960073429],
  [-74.74739837607859, 10.599200248565083],
  [-74.75319671585437, 10.613699913238],
  [-74.75430297805765, 10.621800423132271],
  [-74.75379943837859, 10.632699966973838],
  [-74.75260162311245, 10.642000198185116],
  [-74.7434005742214, 10.710700035248482],
  [-74.73999786350058, 10.7152996060847],
  [-74.74400329546529, 10.729700088428316],
  [-74.74400329632665, 10.736700057637309],
  [-74.74749755886936, 10.744799614212946],
  [-74.74400329647204, 10.750499725636525],
  [-74.74690246631478, 10.758099556373264],
  [-74.74919891342093, 10.765000343639182],
  [-74.74859619166735, 10.771300315584536],
  [-74.75039672901127, 10.779399871561507],
  [-74.75039672901198, 10.789299964867379],
  [-74.74810028080704, 10.800200462320932],
  [-74.74810028029624, 10.811200142416094],
  [-74.7474975581086, 10.822199821671491],
  [-74.7463989254722, 10.827899932665229],
  [-74.74179840093399, 10.841799735925767],
  [-74.74349975598616, 10.850999832190986],
  [-74.74349975532422, 10.85910034202699],
  [-74.7412033086291, 10.866600036408425],
  [-74.73889923105484, 10.87180042269235],
  [-74.73200225851795, 10.876999855476413],
  [-74.73139953630508, 10.882800102085417],
  [-74.73490142802052, 10.88910007494939],
  [-74.74349975529866, 10.895500183521497],
  [-74.75039672803462, 10.900199890207329],
  [-74.75679779021318, 10.904199600333026],
  [-74.76309967097616, 10.90950012191845],
  [-74.76660156258517, 10.915800094978582],
  [-74.77239990211233, 10.922800064551721],
  [-74.77410125789763, 10.927399634719576],
  [-74.77488708552333, 10.931578635579058],
  [-74.77529907260869, 10.933799743944416],
  [-74.77469634961713, 10.939000129589889],
  [-74.77469635030769, 10.948800086607694],
  [-74.77469635068061, 10.958600044563159],
  [-74.77469635028153, 10.970199584581238],
  [-74.79139709511958, 10.990400314796016],
  [-74.80069732649287, 11.004899978926387],
  [-74.80930328337578, 11.017700195424858],
  [-74.82140350360537, 11.02410030402057],
  [-74.82890319874602, 11.030500412110294],
  [-74.83640289303521, 11.040300368919691],
  [-74.83942413376381, 11.046132087541903],
  [-74.8432998662212, 11.053600311322718],
  [-74.84799957309885, 11.064000129866452],
  [-74.85257720895315, 11.072692870987803],
  [-74.84619903530937, 11.073800086575453],
  [-74.83529663070561, 11.06919956206278],
  [-74.81680297884516, 11.065099715885164],
  [-74.80180358874733, 11.063300132857186],
  [-74.7902984619838, 11.059200286926995],
  [-74.75000000053917, 11.04860019694533],
  [-74.72799682570451, 11.038100242047355],
  [-74.69689941353501, 11.025300026013998],
  [-74.67559814463124, 11.015899658657974],
  [-74.63980102529224, 11.004199981595965],
  [-74.61450195342537, 10.990300178360688],
  [-74.59200286818788, 10.979800224281295],
  [-74.56890106167022, 10.972200393245139],
  [-74.53549957292765, 10.961099625034116],
  [-74.50659942622787, 10.958600044662125],
  [-74.50260162386279, 10.958600044622047],
  [-74.49629974390928, 10.95919990519339],
  [-74.48470306405915, 10.956800460470197],
  [-74.46800231934884, 10.954999924231071],
  [-74.46050262456534, 10.954999924048844],
  [-74.44029998791858, 10.955499649307283],
  [-74.42310333202408, 10.95600032831974],
  [-74.40920257524954, 10.957099914554854],
  [-74.38729858367351, 10.960399627626115],
  [-74.36830139213308, 10.962699889649528],
  [-74.35729980422174, 10.965499877691984],
  [-74.34470367402402, 10.97179985035458],
  [-74.33260345488057, 10.972900391168801],
  [-74.32099914576477, 10.974599837858458],
  [-74.3031997676022, 10.987199783361607],
  [-74.30030059808031, 10.990699767913513],
  [-74.293998717846, 10.998200417072335],
  [-74.2853012089565, 11.005599975304843],
  [-74.27030181915734, 11.01949977876174],
  [-74.25710296631517, 11.038499831588984],
  [-74.25769805894072, 11.05169963795074],
  [-74.25129699727533, 11.0741996763463],
  [-74.250198364535, 11.093899726690413],
  [-74.25019836483045, 11.10490036036116],
  [-74.24790191638498, 11.113499641104578],
  [-74.2514038084313, 11.128000259977979],
  [-74.25540161137027, 11.135499954492532],
  [-74.26000213602227, 11.152799606331525],
  [-74.2605972294202, 11.173600196376576],
  [-74.26989746087759, 11.209500312503456],
  [-74.2531967166109, 11.229100227612813],
  [-74.23760223340255, 11.241100311737538],
  [-74.23590087857524, 11.250900269106435],
  [-74.22669982948895, 11.258399963958704],
  [-74.21569824199598, 11.264699936237056],
  [-74.22319793703943, 11.279800414694122],
  [-74.21510314959015, 11.291299819604045],
  [-74.20539855937288, 11.303400039287277],
  [-74.19730377167099, 11.29930019421775],
  [-74.18810272207723, 11.29689979496042],
  [-74.18060302791042, 11.298100471187992],
  [-74.17649841275738, 11.305600166818252],
  [-74.17250061015895, 11.318799972014315],
  [-74.1679000859944, 11.31879997229744],
  [-74.16100311303282, 11.31700038943046],
  [-74.15640258765478, 11.312999725566828],
  [-74.14939880386598, 11.317600250766196],
  [-74.14140319776249, 11.324500084344292],
  [-74.13680267278099, 11.323300361734432],
  [-74.13159942643237, 11.325599670868321],
  [-74.13330078114438, 11.339500427430014],
  [-74.12750244169712, 11.326100349541948],
  [-74.11139679007049, 11.320899963259825],
  [-74.10559844926628, 11.327199936162343],
  [-74.10279846210851, 11.34049987792967],
  [-74.09179687546849, 11.327699661651142],
];
