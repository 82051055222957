import React, { useState } from "react";
import { makeStyles} from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListSubheader,
} from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import api from "../../api";
import useMediaQuery from "@material-ui/core/useMediaQuery";


import Map from './MapLocation'
import Brightness1Icon from "@material-ui/icons/Brightness1";
import InstagramIcon from "@material-ui/icons/Instagram";
// import FacebookIcon from "@material-ui/icons/Facebook";
const useStyles = makeStyles((theme) => ({
  
  margin: {
    margin: "0.4rem 0 0.2rem 0",
    width: "80%",
  },
  container: {
    background: "#ffffff",
    width: "80%",
    padding: "2em 1em 1em 1em",
    borderRadius: "10px",
    alignItems: "strech",
    justifyContent: "center",
    border: "2px solid #EE6B04",
    transform: "translateY(-65px)",
  },
  containerTitle: {
    alignItems: "strech",
    justifyContent: "center",
    display: "flex",
    color: "#EE6B04",
    fontWeight: "bolder",
    paddingBottom: "15px"
  },
  title: {
    color: "000000",
    fontWeight: "bold",
    fontSize: "1.5em",
    padding: "5px 0 5px 0",
  },
  titleXS: {
    color: "000000",
    fontWeight: "bold",
    fontSize: "1em",
    textAlign: "center",
    padding: "5px 0 5px 0",
  },
  grid: {
    justifyContent: "flex-start",
    flexGrow: 1,
  },
  typography: {
    color: "gray",
    fontSize: "0.9em",
  },
  typographyXS: {
    color: "gray",
    fontSize: "0.7em",
  },
  list: {
    maxWidth: 360,
    color: theme.palette.color5.main,
  },
  Infogrid:{
    width: "100%",
    margin: " 5% 0% 0% 0%",
    padding: "0 0 0 0",
  },
  Infogrid2:{
    width: "100%",
    margin: " 2% 0% 0% 0%",
    padding: "0 0 0 0",
  },
  Redes:{
    width: "100%",
    margin: "0 0 0 0",
    padding: "0 0 0 0",
  },
  linked: {
    fontSize: "8vh",
    color: "#0060A9",
    margin: "10px 5px",
  },
  linkedXS: {
    fontSize: "6vh",
    color: "#0060A9",
  },
  message: {
    width: "80%",
  },

  floatingLabelFocusStyle: {
    color: "#00012",
    fontSize: "1.5em",
  },

  withoutLabel: { marginTop: theme.spacing(3) },
  textField: {
    backgroundColor: "#0a1a7c90",
    borderRadius: "5px",
  },
  button: {
    fontSize: "1rem",
    margin: "0.8rem 0",
    padding: "10px 30px",
    borderRadius: "20px",
    color: "#fff",
    display: "inline-block",
    backgroundColor: " #EE6B04 ",
    borderColor: "#EE6B04 ",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#F7750F",
    },
    cursor: "pointer",
    outline: "none",
  },
  root: {
    overflow: "hidden",
    backgroundColor: "#00012",  
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#000",
    },
  },
  // focused: {},
}));

const contact = [
  {
    title: "Email",
    item: "gerencia@innovanex.com.co",
  },
 
  // {
  //   title: "Celular",
  //   // item: "(+57) 316 277 71 03",
  // },
];

const location = [
 
  {
    title: "Dirección",
    item: "Carrera 26 # 12-24 - Barrio Álamos, Pereira, Risaralda, Colombia",
  },
];

function FilledTextField(props) {
  const classes = useStyles();

  return (
    <TextField
      multiline
      InputProps={{ classes, disableUnderline: true }}
      {...props}
    />
  );
}

export default function FormContact() {
  const classes = useStyles();
  const [values, setValues] = useState({
    message: "",
    name: "",
    mail: "",
  });
  const [errorMessage, setErrorMessage] = useState();
  const [errorName, setErrorName] = useState();
  const [errorMail, setErrorMail] = useState();
  const [errorInvalidMail, setErrorInvalidMail] = useState();
  const [open, setOpen] = useState(false);
  const XS = useMediaQuery("(max-width:600px)");
  const SM = useMediaQuery("(max-width:960px)");
  function handleChange(event, key) {
    

    setValues({ ...values, [key]: event.target.value });
  }

  function isEmpty(input) {
    if (
      input === "" ||
      input.trim().length === 0 ||
      input === null ||
      input === undefined ||
      (Array.isArray(input) && input.length === 0)
    ) {
      return true;
    }
    return false;
  }

  function validateValues(values) {
    var haveError = false;
    const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/gi;
    if (isEmpty(values.message)) {
      setErrorMessage(true);
      haveError = true;
    } else {
      setErrorMessage(false);
    }

    if (isEmpty(values.name)) {
      setErrorName(true);
      haveError = true;
    } else {
      setErrorName(false);
    }

    if (isEmpty(values.mail)) {
      setErrorMail(true);
      haveError = true;
    } else {
      setErrorMail(false);
    }
    if (!re.test(values.mail)) {
      setErrorInvalidMail(true);
      haveError = true;
    } else {
      setErrorInvalidMail(false);
    }

    return haveError;
  }

  function handleSubmit(e) {
    e.preventDefault();

    const haveError = validateValues(values);

    if (!haveError) {
    
      setValues({
        message: "",
        name: "",
        mail: "",
      });
      setOpen(true)
     
      
      
      api
      .post("/email", values)
      .then((res) => console.log('mensaje enviado'))
      .catch((err) => console.log('error mail: ',err));
    }
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div className={classes.container}>
      <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={
            5000 
          }
         
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Mensaje enviado exitosamente, pronto recibirás respuesta al correo que suministraste.
          </Alert>
      </Snackbar>
      {/* GRID FORMULARIO CONTACTO */}
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="strech"
      >
        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
          <Typography variant='h4' className={classes.containerTitle}>
            Contactanos
          </Typography>
        </Grid>
      </Grid>
      {/* GRID ERRORES */}
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
      >
        <Grid item lg={8} md={8} sm={8} xl={8} xs={12}>
          <Grid
            container
            direction="row"
            justifycontent="center"
            alignItems="center"
          >
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <FormControl className={classes.message} variant="outlined">
                <FilledTextField
                  error={errorMessage}
                  helperText={
                    errorMessage ? "Este campo no puede ser vacío" : ""
                  }
                  label="Mensaje"
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                  }}
                  className={classes.root}
                  onChange={(e) => handleChange(e, "message")}
                  variant="filled"
                  value={values.message}
                  rows={10}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xl={4} xs={12}>
          <Grid
            container
            direction="row"
            justifycontent="center"
            alignItems="center"
          >
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <FormControl className={classes.margin}>
                <FilledTextField
                  error={errorName}
                  helperText={errorName ? "Este campo no puede ser vacío" : ""}
                  label="Nombre"
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                  }}
                  className={classes.root}
                  onChange={(e) => handleChange(e, "name")}
                  variant="filled"
                  value={values.name}
                />
              </FormControl>
              <FormControl className={classes.margin}>
                <FilledTextField
                  error={errorMail || errorInvalidMail ? true : false}
                  helperText={
                    errorMail
                      ? "Este campo no puede ser vacío"
                      : errorInvalidMail
                      ? "Este correo no es correcto"
                      : null
                  }
                  label="Correo"
                  InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                  }}
                  className={classes.root}
                  onChange={(e) => handleChange(e, "mail")}
                  variant="filled"
                  value={values.mail}
                />
              </FormControl>
              <Button
                onClick={(e) => handleSubmit(e)}
                variant="filled"
                className={classes.button}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
          
        </Grid>
      </Grid>
      {/* GRID INFO DE CONTACTO          */}
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
        className={classes.grid}
      >
        <Grid
          className={classes.Infogrid}
          item
          container
          direction="column"
          justifycontent="center"
          alignItems="center"
          lg={6}
          md={6}
          sm={12}
          xl={6}
          xs={12}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Typography
                  variant="h4"
                  className={XS ? classes.titleXS : classes.title}
                >
                  Información de contacto
                </Typography>
              </ListSubheader>
            }
            className={SM ? classes.listSM : classes.list}
          >
            {contact.map((item) => (
              <ListItem id={item.title}>
                <div style={{ paddingRight: "10px"}}>
                  <Brightness1Icon
                    style={{
                      color: "#0060A9",
                      fontSize: XS ? "8px" : "15px",
                    }}
                  />
                </div>

                <Typography
                  variant="body1"
                  className={XS ? classes.typographyXS : classes.typography}
                >
                  <Typography
                    variant="body2"
                    // className={XS ? classes.typographyXS : classes.typography}
                  >
                    {item.title}{' '}:
                  </Typography>
                  {item.item}
                </Typography>
              </ListItem>
            ))}
            {location.map((item) => (
              <ListItem id={item.title}>
                <div style={{ paddingRight: "10px" }}>
                  <Brightness1Icon
                    style={{
                      color: "#0060A9",
                      fontSize: XS ? "8px" : "15px",
                    }}
                  />
                </div>

                <Typography
                  variant="body1"
                  className={XS ? classes.typographyXS : classes.typography}
                >
                  <Typography
                    variant="body2"
                    // className={XS ? classes.typographyXS : classes.typography}
                  >
                    {item.title}{' '}:
                  </Typography>
                  {item.item}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          className={classes.Infogrid2}
          item
          container
          direction="column"
          justifycontent="center"
          alignItems="center"
          lg={6}
          md={6}
          sm={12}
          xl={6}
          xs={12}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Typography
                  variant="h4"
                  className={XS ? classes.titleXS : classes.title}
                >
                  Ubicación
                </Typography>
              </ListSubheader>
            }
            className={SM ? classes.listSM : classes.list}
          >
          </List>
          <Map  center={[4.797549, -75.6906334]} zoom={12} />
          
        </Grid>
        <Grid
          className={classes.Redes}
          item
          container
          direction="column"
          justifycontent="flex-start"
          alignItems="center"
          lg={12}
          md={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <span>
            {/* <a
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/"
            >
              <FacebookIcon
                className={XS ? classes.linkedXS : classes.linked}
              />
            </a> */}
            <a
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/avenhubmagdalena/"
            >
              <InstagramIcon
                className={XS ? classes.linkedXS : classes.linked}
              />
            </a>
          </span>
        </Grid>
      </Grid>
    </div>
  );
}
