import React from "react";
import {
  Link,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";



// import LinkedInIcon from "@material-ui/icons/LinkedIn";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import YouTubeIcon from "@material-ui/icons/YouTube";



const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    // backgroundImage: "url('footer.png')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // height: "50vh",
  },

  footerXS: {
    paddingTop: "1em",
    backgroundColor: theme.palette.secondary.main,
  },

  footerSM: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  image: {
    width: "90%",
    height: "auto",
  },
  copyright: {
    color: "#fff",
    padding: "2em 0 1em 0",
    textAlign: "center",
  },
  grid: {
    justifyContent: "center",
    flexGrow: 1,
  },
}));



function Copyright() {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.copyright}>
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://innovanex.com.co">
        Innovanex
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer() {
  const classes = useStyles();
  const XS = useMediaQuery("(max-width:600px)");
  const SM = useMediaQuery("(max-width:960px)");

  return (
    <footer
      className={XS ? classes.footerXS : SM ? classes.footerSM : classes.footer}
    >
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
        className={classes.grid}
      >
        {!XS && !SM && (
          <Grid
            item
            container
            direction="column"
            justifycontent="center"
            alignItems="flex-start"
            lg={4}
            md={4}
            sm={3}
            xl={4}
          >
            <Grid item lg={12} md={12} sm={12} xl={12}>
              <div>
                <img className={classes.image} src={"innovanexblanco.png"} alt='logo innovanex'></img>
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Copyright />
    </footer>
  );
}
