import React from 'react'
import InstagramFeed  from 'react-ig-feed'
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import 'react-ig-feed/dist/index.css'

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    margin: "1em 0",
  },
  containerTitle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#EE6B04",
    fontWeight: "bolder",
  },
  typography: {
    margin: "2em 0",
    // textAlign: "justify",
  },
  
  typographyTitle: {
    textAlign: "center",
    "&:hover":{
      textDecoration: "underline",
      },
  },
  card: {
    // background: "#ff0",
    height: "100%",
    maxWidth: "85%",
    padding: "0 5%",
  },
  parent: {
    margin: "5em",
    position: "relative",
    textAlign: "justify",
    transform:'translateY(+60px)'
  },
  Iconstyle:{
    textAlign: "center",
    color: "#0060A9",
    fontSize: "15vh",
    "&:hover": {
      transform: "scale(0.9)"
    },
  },
  media: {
    maxWidth: "50%",
    paddingLeft: "25%",
  },
}));

export default function FeedInstagram(){
  const classes = useStyles();
  const XS = useMediaQuery("(max-width:600px)");
  return (
    <div className={classes.parent}>
      <Typography variant='h4' className={classes.containerTitle}>
        Actualidad
      </Typography>
      <Typography
        variant={XS ? "subtitle2" : "h6"}
        className={classes.container}
      >
        Nuestras más recientes publicaciones y eventos en redes sociales 
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
           <InstagramFeed token="IGQVJXazBTUDl0UzlSV2laZADZASaGZA1R0F4RUUzZAG5lTWxiSlFXaklPZAzNfUkdPcGVzNHFjLW5RcjBYVk1PN2tNUFlRWnJVNFVZASkRXdm9EbHhuX2hTd3dWTFZAhV3ZAqM01ZAQ0V2aHQ0VlM1TXdtWHAtbwZDZD"  counter="6"/> 
        </Grid>
      </Grid>
       
    </div>
    
  );
}




