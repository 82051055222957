import React from "react";
import {Line} from 'react-chartjs-2';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Chart from 'chart.js/auto'
const useStyles = makeStyles((theme) => ({
    container: {
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "50%",
        marginLeft: "25%",
      },
    
    containerTitle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#EE6B04",
    fontWeight: "bolder",
    marginTop: "5em",
    },

    paragraph: {
         //Left-Top-Bottom-Rigth 
    padding: "1% 10% 1% 10%",
    textAlign: "center",
    color : "gray"
    },

}));

export default function ProjectChart(){
    const classes = useStyles();
    
    return(
        <div>
            <Typography className={classes.containerTitle} variant="h4">Estado del proyecto</Typography>
            <Typography className={classes.paragraph} variant="body1">
                Este grafico contiene la informacion del avance acumulado del proyecto AVENHUB Magdalena.
            </Typography>
            <Grid
                container
                direction="row"
                justifycontent="center"
                alignItems="flex-start"
                className={classes.container}
            >
                <Line 
                    data={{
                        labels:['Mes 1','Mes 2','Mes 3','Mes 4','Mes 5','Mes 6',
                        'Mes 7','Mes 8','Mes 9','Mes 10','Mes 11','Mes 12', 'Mes 13', 'Mes 14',
                        'Mes 15', 'Mes 16', 'Mes 17', 'Mes 18', 'Mes 19', 'Mes 20','Mes 21',
                        'Mes 22', 'Mes 23', 'Mes 24'],
                        datasets:[
                            {
                                label: 'Porcentaje de avance planeado',
                                animations: {
                                    y: {
                                        duration: 2000,
                                        delay: 500
                                    },
                                    radius: {
                                        duration: 400,
                                        easing: 'linear',
                                        loop: (context) => context.active
                                    }
                                },
                                data: [0.17, 2.63 , 8.34 , 14.43 , 15.48 , 21.88, 27.86 , 32.35 , 39.46 , 43.67 , 60.20, 67.68,
                                       71.03,74.38,77.72,81.07,84.41,87.71,90.17,92.36,94.26,96.15,98.04,98.22,100],
                                borderColor: '#009DFF',
                                backgroundColor: 'rgba(0, 157, 255, 0.15)',
                                fill: 1,
                                pointStyle: 'circle',
                                pointRadius: 5,
                                tension: 0.5
                            },
                            {
                                label: 'Porcentaje de avance ejecutado',
                                data:[0.17,2.63,8.34,14.43,15.48,21.88,27.86,32.35,39.46,43.67,60.20,67.68,71.03,74.38,77.66,80.77,
                                       83.10,85.68,87.49,88.75,89.84,90.04,90.27,90.45],
                                borderColor: '#FF6800',
                                backgroundColor: 'rgba(255, 104, 0, 0.3)',
                                pointStyle: 'circle',
                                pointRadius: 5,
                                borderDash: [5  , 5],
                                tension: 0.5
                            }
                            
                        ]
                        
                    }}
                    config={{
                        options: {
                            responsive: true,
                            maintainAspectRatio: true,
                            aspectRatio: 2,
                        },
                    }}
                >
                </Line>
            </Grid>
        </div>

    );
}