import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardTravelIcon from '@material-ui/icons/CardTravel';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    margin: "1em 0 ",
  },
  containerTitle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#EE6B04",
    fontWeight: "bolder",
  },
  TextBtn: {
    margin: "2em 0",
    fontSizeAdjust: "auto",
  },
  TextBtnXS: {
    fontSize: "6vmin",
  },
  Iconstyle:{
    textAlign: "center",
    color: "#0060A9",
    fontSize: "10vmin",
    "&:hover": {
      transform: "scale(0.9)"
    },
  },

  IconstyleXS:{
    textAlign: "center",
    color: "#0060A9",
    fontSize: "15vmin",
    "&:hover": {
      transform: "scale(0.9)"
    },
  },

  typographyTitle: {
    textAlign: "center",
    color: "#0060A9",
    "&:hover":{
      textDecoration: "none",
      },
  },
  BtnLink:{
    display: "block flow",
    width: "75%",
    margin: "10px 0 10px 0 ",
    alignItems: "center",
    borderRadius: "8px",
    padding: "5px 20px ",
    textAlign: "center",
    color: "#0060A9",
    boxShadow: "2px 2px 2px 2px #F3F0F0",
    "&:hover": {
        border: "1px solid #EE6B04",
        color: "black",
        transform: "scale(1.1)"
    },
  },
  linkParagraph: {
    color: "#0060A9",
    textTransform: "none",
    "&:hover": {
        color: "#28FB43",
    },
  },
  parent: {
    margin: "10vh 0px 20vh 0",
    position: "relative",
    textAlign: "center",
  },
  parentXS: {
    margin: "0.5vh 0px 30vh 0",
    position: "relative",
    textAlign: "center",
  },
}));

export default function InfoConvocatorias() {
  const classes = useStyles();
  const XS = useMediaQuery("(max-width:600px)");
  const SM = useMediaQuery("(max-width:960px)");
  return (
    <div className={XS || SM ?  classes.parentXS : classes.parent}>
      <Typography variant={XS ? "h6" : "h4"} className={classes.containerTitle}>
        ¿ Necesitas ayuda ?
      </Typography>
      <Typography
        variant={XS ? "subtitle2" : "h6"}
        className={classes.container}
      >
        Enlaces destacados
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
            <Button variant="outlined" href="#abstract"  className={classes.BtnLink}> 
                <CardTravelIcon className={XS ? classes.IconstyleXS: classes.Iconstyle}/> 
                <Typography className={XS? classes.TextBtnXS : classes.TextBtn}>Proyecto AVENHUB Magdalena</Typography>
            </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
            <Button variant="outlined" href="#infoconvocatorias"  className={classes.BtnLink}> 
                <FindInPageIcon className={XS ? classes.IconstyleXS: classes.Iconstyle}/> 
                <Typography  className={XS? classes.TextBtnXS : classes.TextBtn} >Contratación y Convocatorias</Typography>
            </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
            <Button variant="outlined" href="#about"  className={classes.BtnLink}> 
                <RecentActorsIcon className={XS ? classes.IconstyleXS: classes.Iconstyle}/> 
                <Typography  className={XS? classes.TextBtnXS : classes.TextBtn} > Sobre nosotros y contactanos</Typography>
            </Button>
        </Grid>
      </Grid>
    </div>
  );
}
