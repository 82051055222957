import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid , Button , Link} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    margin: "1em 0",
  },
  containerTitle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#EE6B04",
    fontWeight: "bolder",
  },
  typography: {
    margin: "2em 0",
    // textAlign: "justify",
  },
  
  typographyTitle: {
    textAlign: "center",
    "&:hover":{
      textDecoration: "underline",
      },
  },
  link: {
    margin: theme.spacing(0),
    color: "#0060A9",
    textTransform: "none",
    // "&:hover": {
    //   textDecoration: "none",
    // },
  },
  linkParagraph: {
    color: "#A60303",
    fontWeight: "bolder",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkCountry: {
    color: "#A60303",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  nexuraContent: {
    // background: "#A60303bb",
  },
  gefeticContent: {
    // background: "#0B3DC2bb",
  },
  b: {
    color: "#777",
  },
  card: {
    // background: "#ff0",
    height: "100%",
    maxWidth: "85%",
    padding: "0 5%",
  },
  parent: {
    margin: "5em",
    position: "relative",
    textAlign: "justify",
    transform:'translateY(+60px)'
  },
  Iconstyle:{
    textAlign: "center",
    color: "#0060A9",
    fontSize: "15vh",
    "&:hover": {
      transform: "scale(0.9)"
    },
  },

  BtnLink:{
    display: "block",
    width: "75%",
    height: "90%",
    alignItems: "center",
    borderRadius: "10px",
    padding: "0  5px 30px 5px",
    textAlign: "center",
    color: "#0060A9",
    boxShadow: "2px 2px 2px 2px #F3F0F0",
    "&:hover": {
        border: "1px solid #EE6B04",
        color: "black",
        transform: "scale(1.1)"
    },
  },

  TextBtn: {
    fontWeight: "bold",
    color:"white",
    margin: "1em 1em",
    fontSizeAdjust: "auto",
  },
  TextBtnXS: {
    fontSize: "1.7vmin",
    fontWeight: "bold",
    color:"white",
    margin: "1em 1em",
    fontSizeAdjust: "auto",
  },
  BtnLinkXS:{
    display: "block",
    width: "100%",
    height: "auto",
    margin: "10px 0 10px 0 ",
    alignItems: "center",
    borderRadius: "10px",
    padding: "15px 10px ",
    textAlign: "center",
    color: "#0060A9",
    boxShadow: "2px 2px 2px 2px #F3F0F0",
    "&:hover": {
        border: "1px solid #EE6B04",
        color: "black",
        transform: "scale(1.1)"
    },
  },
  media: {
    maxWidth: "50%",
    paddingLeft: "25%",
  },
  BtnForm: {
    backgroundColor: "#EE6B04",
    width: "30%",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#EE6B04",
      transform: "scale(1.1)"
    },
  },
  BtnFormXS: {
    width: "40%",
    height: "20%",
    backgroundColor: "#EE6B04",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#EE6B04",
      transform: "scale(1.1)"
    },
  },

  article: {
    position: "relative",
    margin: '2em 0 0 0',
    textAlign:'center',
    height: 'auto',
    overflow: 'hidden',
    width: 'auto',
 
    // height: "90vh",
    // background: "#ffffff11",
    // backdropFilter: "blur(10px)",
    // display: "inline-grid",
  },

  articleXS: {
    position: "relative",
    margin: '2em 0 0 0',
    textAlign:'center',
    height: 'auto',
    overflow: 'hidden',
    width: 'auto',
    height: '200%'
  },
}));

export default function IDataQbo() {
  const classes = useStyles();
  const XS = useMediaQuery("(max-width:600px)");
  const SM = useMediaQuery("(max-width:960px)");
  return (
    <div className={classes.parent}>
      <Typography variant='h4' className={classes.containerTitle}>
      Potencia el liderazgo y la innovación en tu organización con los talleres 
     
      </Typography>
      <Typography variant='h4' className={classes.containerTitle}>
      “Neurotraining en turismo con AvenHub Magdalena”
      </Typography>

      
      <Typography
        variant={XS ? "subtitle2" : "h6"}
        className={classes.container}
      >
       La tecnología evoluciona con rapidez, no pierdas la oportunidad de llevar tu negocio u organización al siguiente nivel con las nuevas herramientas que están llegando para renovar el sector turístico del departamento del Magdalena.

Aprende con expertos sobre neurociencia, Realidad Virtual y Realidad Aumentada; potencia tu capacidad de liderazgo y participa por un cupo para promover tus servicios desarrollando habilidades de liderazgo e innovación con la neuroplasticidad.

<br/><br/>Este proyecto es liderado por la Unión Temporal Innovanex, en alianza con la Gobernación del Magdalena y la Universidad del Magdalena,con el financiamiento del Ministerio de Ciencia, Tecnología e Innovación a través del Sistema General de Regalías.
      </Typography>
      <Typography
          variant={XS ? "subtitle2" : "h6"}
          className={classes.container}
      >
          <br/>Para inscribirte y participar, lee los documentos a continuación:

      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
          <Button variant="outlined" target="_blank" href= {"IDataQbo/ADENDA Nro 1.pdf"}  className={XS  || SM ?  classes.BtnLinkXS : classes.BtnLink}> 
                <PictureAsPdfIcon className={classes.Iconstyle}/> 
                    <br/>Cambios en la convocatoria
          </Button>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
          <Button variant="outlined" target="_blank" href= {"IDataQbo/TERMINOS DE REFERENCIA SELECCION BENEFICIARIOS CAPACITACION AJUSTADOS.pdf"}  className={XS  || SM ?  classes.BtnLinkXS :classes.BtnLink}> 
              <PictureAsPdfIcon className={classes.Iconstyle}/> 
              <br/> Término de Referencia para Selección de Beneficiarios
          </Button>
          
        </Grid>
             
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid className={ XS||SM ? classes.articleXS : classes.article} item lg={12} md={12} sm={12} xl={12} xs={12}>
          <Button href="https://forms.gle/9Bhv7MCT3FnaoHfU7" target="_blank" className={XS? classes.BtnFormXS : classes.BtnForm}>
                  <Typography className={SM? classes.TextBtnXS: classes.TextBtn}>Inscríbete aquí</Typography>
          </Button> 
        </Grid>
          
        
      </Grid>
    </div>
  );
}
