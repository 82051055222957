import { makeStyles } from "@material-ui/core/styles";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';


import BeenhereIcon from '@material-ui/icons/Beenhere';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import RepeatIcon from '@material-ui/icons/Repeat';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import GroupIcon from '@material-ui/icons/Group';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import SchoolIcon from '@material-ui/icons/School';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ComputerIcon from '@material-ui/icons/Computer';
import BrushIcon from '@material-ui/icons/Brush';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CheckIcon from '@material-ui/icons/Check';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';

import Paper from '@material-ui/core/Paper';
import {Typography,Grid} from '@material-ui/core';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2em 5%",
  },

  containerPaper: {
    padding: "0 15% 0 15%",
    minWidth: "510px",
  },

  containerPaperXS: {
    padding: "0 0 0 0"
  },

  paper: {
    padding: '6px 16px',
  },


  containerTitle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    margin: "1em 0",
    color: "#EE6B04",
    fontWeight: "bolder",
  },

  tldotPrimary:{
    backgroundColor: "#0060A9"
  },

  tldotSecundary:{
    backgroundColor: "#FFFFFF",
    borderColor: "#0060A9"
  },

  tlbutttonPrimary:{
    color: "#FFFFFF",
    "&:hover": {
      color: "#28FB43",
    },
  },

  tlbutttonSecundary:{
    color: "#0060A9",
    "&:hover": {
      color: "#28FB43",
    },
  },

  secondaryTail: {
    backgroundColor: "#EE6B04"
  },
  
}));

export default function CustomizedTimeline() {
  const classes = useStyles(); 
  const XS = useMediaQuery("(max-width:600px)");
  const SM = useMediaQuery("(max-width:960px)");

  return (
    <div className={classes.container}>
      <Typography className={classes.containerTitle} variant="h4">
        Línea de tiempo del proyecto
      </Typography>
      <Grid
        className={XS || SM ? classes.containerPaperXS : classes.containerPaper}
        container
        direction="row"
        justifycontent="center"
        alignItems="strech"
        flexWrap="wrap"
      >
      <Timeline align="alternate">
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                4 Junio 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <BeenhereIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Aprobación del proyecto 
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                1 Agosto 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <GroupAddIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Contratación
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                1 Septiembre 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <HowToRegIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Articulación de actores
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                1 Septiembre 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <PlayCircleFilledWhiteIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Inicio ejecución objetivos
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                3 Septiembre 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <AssignmentIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Diagnóstico sector turismo 
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                1 Octubre 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <DeveloperModeIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Desarrollo de software
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Octubre 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <AssignmentTurnedInIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                 Lanzamiento del proyecto con operadores turísticos
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                1 Octubre 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <PresentToAllIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Lanzamiento proyecto con operadores
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                1 de Noviembre 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <GroupAddIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Contrataciones de los servicios tecnologicos
                  
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
              25 de Noviembre 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <GroupIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Foro "Turismo inteligente reactivémonos- la apropiación del turismo con tecnologia"
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                 Diciembre 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <ArtTrackIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Inicio Maquilación kioscos
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                28 de Diciembre 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <ArtTrackIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Especificacion detallada de productos
                  
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
               30 de Enero 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <ScheduleIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Programación de los recorridos por los 14 municipios del departamento de Magdalena 
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                28 de Febrero 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <AlarmOnIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                 Inicio de los recorridos y socialización de futuros talleres de realidad virtual
                  
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Marzo 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <GroupIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Integración equipo cientifico
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Marzo 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <SchoolIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                 Capacitación equipo de trabajo en metodologias agíles
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Marzo 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <ImageSearchIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Investigación de estimulación transcraneal
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Marzo 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <ImageSearchIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Investigación EEG y ECG
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Marzo- Abril 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <SchoolIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                 Desarrollo de talleres con comunidad CTeI
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Abril 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <ShoppingCartIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Compra de equipos y cotizacion caminadoras realidad virtual
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
               Mayo 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <AssignmentIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                 Diseño final y materiales a usar para los stands
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Mayo 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <ComputerIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Avances proceso estimulación trascraneal
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
               Junio 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <AssignmentIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                 Aprobación de cambios y ajustes de los valores de los equipos
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Junio 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <BrushIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Diseño de 10 de los escenarios
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
               Junio 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <AssignmentTurnedInIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                 Compra de impresoras 3D
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Julio 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <CallReceivedIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Recepción de 6 plataformas Virtuix Omni
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
               Julio 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <CallReceivedIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                 Recepción de dos escáneres 3D
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Julio 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <CallReceivedIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Recepción de las impresoras 3D
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Julio- Agosto 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <AssignmentIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                   Talleres comunidad académica
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
               Agosto 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <CheckIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                 Definición total de los guiones
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Agosto 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <DriveEtaIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Inicio de los recorridos de los 14 municipios
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
               Agosto 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <EventAvailableIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent >
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                   Cumplimiento de actividades de divulgación de resultados
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Agosto 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <OndemandVideoIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Se realizó el foro "Barreras tecnológicas en el turismo, hacía la teletransportación”
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Agosto 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <PresentToAllIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Presentación de caminadora Omni 
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Octubre - Noviembre 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <GroupAddIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Talleres Neurotraining 
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Noviembre 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <EventAvailableIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Terminación de captura de contenido en 13 municipios
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Diciembre 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <EventAvailableIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Se culminó la consultoría en turismo con la definición de la estrategia de promoción de la oferta turística del Magdalena
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Enero 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <EventAvailableIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Se revisó la impresión tótem en su totalidad y se realizaron algunos lineamientos para ajustes en las impresiones de los demás tótems.
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Enero 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <EventAvailableIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Se realizó socialización del proyecto en la Feria Internacional de Turismo, FITUR donde se tuvo un gran impacto y acogida.
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Febrero 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <EventAvailableIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                    Se realizó socialización del proyecto en la Feria Nacional de Turismo, Anato donde se tuvo un gran impacto y acogida, más de 5.500 asistente al Stand del Magdalena
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Marzo 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <GroupIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                 Se abrió convocatoria para realizar los eventos del proyecto de redes colaborativas con operadores turísticos.
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Marzo 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <ImageSearchIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Se continúa realizando el proceso de evaluación de “SENSE OF PRESENCE” con los play test y mediciones de EEG y ECG de los 14 municipios en su segunda versión.
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Abril 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <GroupIcon  className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Se abrió la convocatoria n°2 para 185 beneficiarios operadores turísticos para generar sus micrositios en la plataforma tecnológica.
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Abril 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <HowToRegIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Se realizaron 6 eventos de redes colaborativas con #199 asistentes
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Mayo 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <EventAvailableIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Se finalizó el proceso de tomada de datos y el proceso de evaluación de “SENSE OF PRESENCE” con los play test y mediciones de EEG y ECG de los 14 municipios 
en su segunda versión.
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Mayo 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <HowToRegIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Se terminó la impresión de los demás tótems y se instalaron las respectivas bases para instalación en los stands.
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Mayo 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <EventAvailableIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Se finalizó el desarrollo de las experiencias en realidad virtual y realidad aumentada donde se integraron los contenidos capturados durante el mes de marzo de 2023 y 
pasan ahora a su revisión final durante el proceso de liquidación de los contratos.
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Junio 2023
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotPrimary}>
                <HowToRegIcon className={classes.tlbutttonPrimary} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                Finalizó la segunda convocatoria para la selección de los operadores turísticos beneficiarios para generar sus micrositios en la plataforma tecnológica, los cuales están a 
la espera de revisión del ajuste en trámite para parametrizar con los contenidos digitales faltantes.
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">

              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.tldotSecundary}>
                <BeenhereIcon className={classes.tlbutttonSecundary} />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              {/* <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">

                </Typography>
              </Paper> */}
            </TimelineContent>
          </TimelineItem>
          
       </Timeline>
      </Grid>
        
  </div>
  );
}
