import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid , Button , Link} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    margin: "1em 0",
  },
  containerTitle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#EE6B04",
    fontWeight: "bolder",
  },
  typography: {
    margin: "2em 0",
    // textAlign: "justify",
  },
  
  typographyTitle: {
    textAlign: "center",
    "&:hover":{
      textDecoration: "underline",
      },
  },
  link: {
    margin: theme.spacing(0),
    color: "#000",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkParagraph: {
    color: "#A60303",
    fontWeight: "bolder",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkCountry: {
    color: "#A60303",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  nexuraContent: {
    // background: "#A60303bb",
  },
  gefeticContent: {
    // background: "#0B3DC2bb",
  },
  b: {
    color: "#777",
  },
  card: {
    // background: "#ff0",
    height: "100%",
    maxWidth: "85%",
    padding: "0 5%",
  },
  parent: {
    margin: "5em",
    position: "relative",
    textAlign: "justify",
    transform:'translateY(+60px)'
  },
  Iconstyle:{
    textAlign: "center",
    color: "#0060A9",
    fontSize: "15vh",
    "&:hover": {
      transform: "scale(0.9)"
    },
  },
  TextBtn: {
    fontWeight: "bold",
    color:"white",
    margin: "1em 1em",
    fontSizeAdjust: "auto",
  },
  TextBtnXS: {
    fontSize: "1.7vmin",
    fontWeight: "bold",
    color:"white",
    margin: "1em 1em",
    fontSizeAdjust: "auto",
  },
  BtnLink:{
    display: "block",
    width: "75%",
    height: "90%",
    alignItems: "center",
    borderRadius: "10px",
    padding: "0  5px 30px 5px",
    textAlign: "center",
    color: "#0060A9",
    boxShadow: "2px 2px 2px 2px #F3F0F0",
    "&:hover": {
        border: "1px solid #EE6B04",
        color: "black",
        transform: "scale(1.1)"
    },
  },
  BtnLinkXS:{
    display: "block",
    width: "100%",
    height: "auto",
    margin: "10px 0 10px 0 ",
    alignItems: "center",
    borderRadius: "10px",
    padding: "15px 10px ",
    textAlign: "center",
    color: "#0060A9",
    boxShadow: "2px 2px 2px 2px #F3F0F0",
    "&:hover": {
        border: "1px solid #EE6B04",
        color: "black",
        transform: "scale(1.1)"
    },
  },
  BtnForm: {
    backgroundColor: "#EE6B04",
    width: "30%",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#EE6B04",
      transform: "scale(1.1)"
    },
  },
  BtnFormXS: {
    width: "40%",
    height: "20%",
    backgroundColor: "#EE6B04",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#EE6B04",
      transform: "scale(1.1)"
    },
  },
  article: {
    position: "relative",
    margin: '2em 0 0 0',
    textAlign:'center',
    height: 'auto',
    overflow: 'hidden',
    width: 'auto',
 
    // height: "90vh",
    // background: "#ffffff11",
    // backdropFilter: "blur(10px)",
    // display: "inline-grid",
  },

  articleXS: {
    position: "relative",
    margin: '2em 0 0 0',
    textAlign:'center',
    height: 'auto',
    overflow: 'hidden',
    width: 'auto',
    height: '200%'
  },
  media: {
    maxWidth: "50%",
    paddingLeft: "25%",
  },
}));

export default function InfoConvocatorias() {
  const classes = useStyles();
  const XS = useMediaQuery("(max-width:600px)");
  const SM = useMediaQuery("(max-width:960px)");
  return (
    // Convocatoria Operadores
    <div className={classes.parent}>
      <Typography variant='h4' className={classes.containerTitle}>
        Información convocatorias vigentes
      </Typography> 
      <Typography
        variant={XS ? "subtitle2" : "h6"}
        className={classes.container}
      >
        En el momento no hay convocatorias disponibles
      </Typography>

      {/* <Typography
        variant={XS ? "subtitle2" : "h6"}
        className={classes.container}
      >

      En atención a los requerimientos del Proyecto AVENHUB Magdalena, se presenta convocatoria abierta para 185 operadores 
      turísticos que serán beneficiarios de la configuración de un micrositio web, herramienta que les permitirá la promoción y difusión de 
      sus productos y servicios turísticos, que estará visibles en la plataforma axist.com.co, y será promocionada a través del dominio
      turismomagdalena.com, así como en una aplicación móvil y en los centros de experiencia, administradospor UT INNOVANEX que se ubicaran en 
      la ciudad de Santa Marta. En el ANEXO 1 encontrará un explicativode las plataformas y los beneficios que tendrán los operadores seleccionados.<br/><br/>

      En la presente convocatoria se ajustaron los requisitos mínimos establecidos por UT INNOVANEX y el comité
      de Alianza, operador del proyecto AVENHUB Magdalena, para optar a ser uno de los seleccionados como
      beneficiario de la convocatoria.<br/><br/>

      Con el objetivo de reunir de forma organizada las postulaciones con los documentos correspondientes, se
      comparte la documentación de la combocatorio y el formulario donde se puedan adjuntar los documentos además de suministrar la información
      correspondiente del postulante.<br></br>
      <br></br>
      Para inscribirte y participar, lee los documentos a continuación:

      </Typography>

      
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
          <Button variant="outlined" target="_blank" href= {"convocatoria/CONVOCATORIAN2 - UT INNOVANEX.pdf"}  className={XS  || SM ?  classes.BtnLinkXS :classes.BtnLink}> 
              <PictureAsPdfIcon className={classes.Iconstyle}/> 
              <br/> CONVOCATORIA N°2 - UT INNOVANEX OPERADORES
          </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
          <Button variant="outlined" target="_blank" href= {"convocatoria/ANEXO 1 Descripción beneficios.pdf"}  className={XS  || SM ?  classes.BtnLinkXS : classes.BtnLink}> 
                <PictureAsPdfIcon className={classes.Iconstyle}/> 
                    <br/>ANEXO 1 DESCRIPCIÓN DE BENEFICIOS DE LA CONVOCATORIA
          </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
           <Button variant="outlined" target="_blank" href= {"convocatoria/ANEXO 2 Carta de compromiso.pdf"}  className={XS  || SM ?  classes.BtnLinkXS :classes.BtnLink}> 
                <PictureAsPdfIcon className={classes.Iconstyle}/> 
                    <br/>ANEXO 2 CARTA DE COMPROMISO ASPIRANTE CONVOCATORIA
           </Button>
        </Grid>
      </Grid> */}
      
      {/* <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
           <Button variant="outlined" target="_blank" href= {"convocatoria/ANEXO 3 Contrato Beneficiarios.pdf"}  className={XS  || SM ?  classes.BtnLinkXS :classes.BtnLink}> 
                <PictureAsPdfIcon className={classes.Iconstyle}/> 
                    <br/>ANEXO 3 CONTRATO PARA LOS BENEFICIARIOS DE LA CONVOCATORIA
           </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
          <Button variant="outlined" target="_blank" href= {"convocatoria/ANEXO 4 Política de tratamiento de datos.pdf"}  className={XS  || SM ?  classes.BtnLinkXS : classes.BtnLink}> 
                <PictureAsPdfIcon className={classes.Iconstyle}/> 
                    <br/>ANEXO 4 POLITICA DE TRATAMIENTO DE DATOS DE LA CONVOCATORIA
          </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
          <Button variant="outlined" target="_blank" href= {"convocatoria/ADENDA Nro 3 185 Operadores Turísticos.pdf"}  className={XS  || SM ?  classes.BtnLinkXS : classes.BtnLink}> 
                <PictureAsPdfIcon className={classes.Iconstyle}/> 
                    <br/>ADENDA Nro. 3 CONVOCATORIA: SELECCIÓN PARA 185 OPERADORES TURÍSTICOS
          </Button>
        </Grid>
      </Grid> */}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
       {/* <Grid className={ XS||SM ? classes.articleXS : classes.article} item lg={12} md={12} sm={12} xl={12} xs={12}>
          <Button href="https://forms.gle/i4mqjfggn1iab8bJ8" target="_blank" className={XS? classes.BtnFormXS : classes.BtnForm}>
                  <Typography className={SM? classes.TextBtnXS: classes.TextBtn}>Inscríbete aquí a la convocatoria de Operadores</Typography>
          </Button> 
        </Grid> */}
          
        
      </Grid>

      <Typography
        variant={XS ? "subtitle2" : "h6"}
        className={classes.container}
      >
        <br></br>
        <br></br>
        Resultados convocatoria n°2 operadores turisticos
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item lg={4} md={12} sm={12} xl={4} xs={12}>
        </Grid>

        <Grid item lg={4} md={12} sm={12} xl={4} xs={12}>
          <Button variant="outlined" target="_blank" href= {"convocatoria/PUBLICACIÓN DEL BANCO DE ELEGIBLES 2da CONVOCATORIA.pdf"}  className={XS  || SM ?  classes.BtnLinkXS :classes.BtnLink}> 
              <PictureAsPdfIcon className={classes.Iconstyle}/> 
              <br/> Resultados banco elegibles convocatoria #2 operadores turisticos
          </Button>
        </Grid>

        <Grid item lg={4} md={12} sm={12} xl={4} xs={12}>
        </Grid>
      </Grid>

      {/* Talleres innovación y tecnologia */}

      {/* <Typography
        variant={XS ? "subtitle2" : "h6"}
        className={classes.container}
      >
        CONVOCATORIA PARA LOS EVENTOS DE REDES COLABORATIVAS "INNOVACIÓN Y TECNOLOGÍA APLICADAS A LAS EMPRESAS DE TURISMO DE AVENTURA EN EL DEPARTAMENTO DEL MAGADALENA"
      </Typography>

      <Typography
        variant={XS ? "subtitle2" : "h6"}
        className={classes.container}
      >

      Dirigido a empresarios y emprendedores del Departamento del Magdalena involucrados en la prestación de servicios turísticos de aventura, así como 
      aquellos innovadores que estén incursionando en el campo de las tecnologías que puedan servir de soporte a la industria de viajes y turismo.
      
      Requisitos y documentos para participar

      - Ser empresario del sector turismo en el Departamento del Magdalena.

      - Tener RNT vigente o RUT donde se indique que haces parte del sector turístico.

      </Typography>


      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
       <Grid className={ XS||SM ? classes.articleXS : classes.article} item lg={12} md={12} sm={12} xl={12} xs={12}>
          <Button href="https://forms.gle/ASpmJdSyRihyvDZt5" target="_blank" className={XS? classes.BtnFormXS : classes.BtnForm}>
                  <Typography className={SM? classes.TextBtnXS: classes.TextBtn}>Inscríbete aquí a los eventos de redes colaborativas</Typography>
          </Button> 
        </Grid>
      </Grid> */}
      
    </div>
  );
}
