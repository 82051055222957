import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles , useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button, Link, Typography} from "@material-ui/core";
import MobileStepper from '@material-ui/core/MobileStepper';
//import Paper from '@material-ui/core/Paper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const tutorialSteps = [    
  // {
  //  label: 'Convocatoria operadores n2',
  //  imgPath:
  //    'Slide/operadores2.png',
  //  link:"#infoconvocatorias",
  // },
  {
    label: 'Anatao',
    imgPath:
      'Slide/Anato.png',
    link:"https://forms.gle/ASpmJdSyRihyvDZt5",
  },
  {
    label: 'Foro VRAgosto',
    imgPath:
      'Slide/Slide2.png',
  },
  // {
  //   label: 'Talleres Cuidadanos',
  //   imgPath:
  //      'Slide/Talleres ciudadanos.png',
  // },
  // {
  //   label: 'Talleres experiencia',
  //   imgPath:
  //     'Slide/Talleres Experiencia.png',
  // },
  // {
  //   label: 'Talleres neurotraining',
  //   imgPath:
  //      'Slide/Talleres neurotraining.png',
  // },
  // {
  //    label: 'Taller',
  //    imgPath:
  //      'Slide/Inscríbete a los talleres de AvenHub Magdalena.png',
  //    link:"https://forms.gle/aox4mi4GwYQ7Wgfm8",
  // },
];
const useStyles = makeStyles((theme) => ({
  
  button: {
    fontSize: "1rem",
    margin: "0.8rem 0",
    padding: "10px 30px",
    borderRadius: "20px",
    color: "#fff",
    display: "inline-block",
    backgroundColor: " #EE6B04 ",
    borderColor: "#EE6B04 ",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#F7750F",
    },
    cursor: "pointer",
    outline: "none",
  },
  container: {
    position: "relative",
    textAlign: "justify",
    // backgroundImage: "url('cover.jpg')",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    transform:'translateY(-90px)'
  },
  containerXS: {
    position: "relative",
    textAlign: "justify",
    // backgroundImage: "url('cover.jpg')",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    transform:'translateY(-90px)'
  },
  containerSM: {
    position: "relative",
    textAlign: "justify",
    // backgroundImage: "url('cover.jpg')",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    transform:'translateY(-90px)'
  },
  containerMD: {
    position: "relative",
    textAlign: "justify",
    // backgroundImage: "url('cover.jpg')",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    transform:'translateY(-90px)'
  },
  paper: {
    alignItems: "flex-end",
    justifyContent: "center",
    display: "flex",
    padding: "0 0 0 10%",
  },
  buttonCarousell: {
    display: "flex",
    transform:'translateY(-30vh)'
  },
  buttonArrow: {
   borderRadius: "80%",
   fontSize: "25px",
   color: "#28CF32",
   border: "3px solid #28CF32"
  },
  BtnForm: {
    backgroundColor: "#C51863",
    color: "white",
    "&:hover": {
      backgroundColor: "#C51863",
      transform: "scale(1.1)"
    },
  },
  BtnFormXS: {
    width: "30%",
    height: "15%",
    backgroundColor: "#C51863",
    color: "white",
    "&:hover": {
      backgroundColor: "#C51863",
      transform: "scale(1.1)"
    },
  },
  TextBtn: {
    fontWeight: "bold",
    color:"white",
    margin: "1em 1em",
    fontSizeAdjust: "auto",
  },
  TextBtnXS: {
    fontSize: "1.7vmin",
    fontWeight: "bold",
    color:"white",
    margin: "1em 1em",
    fontSizeAdjust: "auto",
  },
  title: {
    color: "#fff",
  },
  DivImg:{
    height: "auto",
    width: "auto",
    overflow: 'hidden',
    alignItems: "center",
    verticalAlign: "middle",
    objectFit: "contain",
  },

  DivImgXS:{
    height: "120%",
    width: "100%",
    overflow: 'hidden',
    alignItems: "center",
    verticalAlign: "middle",
    objectFit: "contain",
  },
  img: {
    maxWidth: "100%",
    // maxHeight: "660px",
    width:"100%",
    height: "auto",
    objectFit: "contain",
  },
  mobilestepper:{
    padding: 0,
    backgroundColor: 'transparent',
    transform:'translateY(-100px)'
  },

  article: {
    position: "relative",
    transform:'translateY(+90px)',
    margin: '0 0 0 0',
    textAlign:'center',
    height: 'auto',
    overflow: 'hidden',
    width: 'auto',
 
    // height: "90vh",
    // background: "#ffffff11",
    // backdropFilter: "blur(10px)",
    // display: "inline-grid",
  },

  articleXS: {
    position: "relative",
    transform:'translateY(+90px)',
    margin: '0 0 0 0',
    textAlign:'center',
    height: 'auto',
    overflow: 'hidden',
    width: 'auto',
    height: '200%'
  },
 
 
}));

export default function Cover() {
  const classes = useStyles();
  const theme = useTheme();
  const XS = useMediaQuery("(max-width:600px)");
  const SM = useMediaQuery("(max-width:960px)");
  const MD = useMediaQuery("(max-width:1280px)");
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <div
      className={
        XS
          ? classes.containerXS
          : SM
          ? classes.containerSM
          : MD
          ? classes.containerMD
          : classes.container
      }
    >
      <Grid
        container
        direction="row"
        justifycontent="flex-start"
        alignItems="center"
      >
        <Grid className={ XS||SM ? classes.articleXS : classes.article} item lg={12} md={12} sm={12} xl={12} xs={12} >
          {/* <Paper square elevation={0} className={classes.header}>
           <Typography>{tutorialSteps[activeStep].label}</Typography>
          </Paper> */}
          <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {tutorialSteps.map((step, index) => (
                <div className={XS || SM ? classes.DivImgXS :classes.DivImg} key={step.label}>
                  <a href={step.link}>
                    {Math.abs(activeStep - index) <= 2 ? (
                        <img className={classes.img}  src={step.imgPath} alt={step.label}/>
                    ) : null}
                  </a>
                </div>
              ))}
          </AutoPlaySwipeableViews>
            <MobileStepper className={classes.mobilestepper}
              steps={maxSteps}
              position='bottom'
              variant='dots'
              activeStep={activeStep}
              nextButton={
                <Button className={classes.buttonCarousell} size="large" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft className={classes.buttonArrow} /> : <KeyboardArrowRight className={classes.buttonArrow}/>}
                </Button>
              }
              backButton={
                <Button className={classes.buttonCarousell} size="large" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight className={classes.buttonArrow}/> : <KeyboardArrowLeft className={classes.buttonArrow}/>}
                </Button>
              }
            />
          {/* <Button href="https://forms.gle/aox4mi4GwYQ7Wgfm8" target="_blank" className={XS? classes.BtnFormXS : classes.BtnForm}>
                <Typography className={SM? classes.TextBtnXS: classes.TextBtn}>Click aquí para inscribirté</Typography>
          </Button> */}
        </Grid> 
        
          
      </Grid>
    </div>
  );
}
